export const getVideo = () => {
    return dispatch => {
        fetch("data.json", { headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }})
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(async data => {
            if (data["landing-page-1"] && 
                data["landing-page-2"]["vantagens-do-clube"] &&
                data["landing-page-2"]["vantagens-do-clube"]["video"] &&
                data["landing-page-2"]["vantagens-do-clube"]["video"]["thumbnail"] && 
                data["landing-page-2"]["vantagens-do-clube"]["video"]["id"])
            {
                const imageName = data["landing-page-2"]["vantagens-do-clube"]["video"]["thumbnail"];
                const imageRequired = await require(`../../assets/images/landing-page-2/${imageName}`);
                const imageRequiredDefault = imageRequired.default;
                const videoId = data["landing-page-2"]["vantagens-do-clube"]["video"]["id"];

                dispatch({ type: "SET_LANDING_2_VANTAGENS_DO_CLUBE_VIDEO_THUMBNAIL", payload: imageRequiredDefault });
                dispatch({ type: "SET_LANDING_2_VANTAGENS_DO_CLUBE_VIDEO_ID", payload: videoId });
            }
        });
    }
}

export const getTitulosMaisRecentes = () => {
    return dispatch => {
        fetch("data.json", { headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }})
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(async data => {
            if (data["landing-page-1"] && 
                data["landing-page-2"]["vantagens-do-clube"] &&
                data["landing-page-2"]["vantagens-do-clube"]["titulos-mais-recentes"])
            {
                const lancamentosList = [];

                await data["landing-page-2"]["vantagens-do-clube"]["titulos-mais-recentes"].forEach(async (lancamento) => {
                    const lancamentoImage = await require(`../../assets/images/lancamentos-revistas/${lancamento.imagem}`);
                    const lancamentoImageAlt = lancamento["imagem-alt"];
                    const lancamentoDate = lancamento.data;
                    const lancamentoTitle = lancamento.titulo;
                    const lancamentoCode = lancamento.codigo;
                    const lancamentoDescricao = lancamento.descricao;

                    const lancamentoObj = {
                        image: lancamentoImage.default,
                        imageAlt: lancamentoImageAlt,
                        date: lancamentoDate,
                        title: lancamentoTitle,
                        code: lancamentoCode,
                        descricao: lancamentoDescricao,
                    }

                    lancamentosList.push(lancamentoObj);
                });

                dispatch({ type: "SET_VANTAGENS_DO_CLUBE_LANDING_2_TITULOS_MAIS_RECENTES", payload: lancamentosList });
            }
        });
    }
}

export const setModal1Opened = state => {
    return dispatch => {
        dispatch({ type: "SET_MODAL_OPENED_VANTAGENS_CLUBE_LANDING_2", payload: state });
    };
}