const INITIAL_STATE = { 
    faqOpened: false,
    loginFormOpened: false,
    esqueciSenhaFormOpened: false,
};

export const reducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_FAQ_OPENED":
            return { ...state, faqOpened: action.payload }
        
        case "SET_LOGIN_FORM_OPENED":
            return { ...state, loginFormOpened: action.payload }
    
        case "SET_ESQUECI_SENHA_FORM_OPENED":
            return { ...state, esqueciSenhaFormOpened: action.payload }
        
        default:
            return state;
    }
}