const INITIAL_STATE = {
    faqModalOpened: false,
    termosCondicoesModalOpened: false,
    politicaPrivacidadeModalOpened: false
}

export const reducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_FAQ_MODAL_OPENED":
            return { ...state, faqModalOpened: action.payload };
        case "SET_TERMOS_CONDICOES_MODAL_OPENED":
            return { ...state, termosCondicoesModalOpened: action.payload };
        case "SET_POLITICA_PRIVACIDADE_MODAL_OPENED":
            return { ...state, politicaPrivacidadeModalOpened: action.payload };
        default:
            return state;
    }
}