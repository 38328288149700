import React, { Component } from "react";
import YouTube from 'react-youtube';

export class VideoEmbed extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <article className="video-embed">
                <YouTube
                    videoId={this.props.videoId}                  // defaults -> null
                    id={this.props.id}                       // defaults -> null
                    className={this.props.className}                // defaults -> null
                    containerClassName={this.props.containerClassName}       // defaults -> ''
                    opts={this.props.opts}                        // defaults -> {}
                    onReady={this.props.onReady}                    // defaults -> noop
                    onPlay={this.props.onPlay}                     // defaults -> noop
                    onPause={this.props.onPause}                    // defaults -> noop
                    onEnd={this.props.onEnd}                      // defaults -> noop
                    onError={this.props.onError}                    // defaults -> noop
                    onStateChange={this.props.onStateChange}              // defaults -> noop
                    onPlaybackRateChange={this.props.onPlaybackRateChange}       // defaults -> noop
                    onPlaybackQualityChange={this.props.onPlaybackQualityChange}    // defaults -> noop
                    />
            </article>
        )
    }
}

export default VideoEmbed