export const setFaqModalOpened = state => {
    return dispatch => {
        dispatch({ type: "SET_FAQ_MODAL_OPENED", payload: state });
    }
}

export const setTermosCondicoesModalOpened = state => {
    return dispatch => {
        dispatch({ type: "SET_TERMOS_CONDICOES_MODAL_OPENED", payload: state });
    }
}

export const setPoliticaPrivacidadeModalOpened = state => {
    return dispatch => {
        dispatch({ type: "SET_POLITICA_PRIVACIDADE_MODAL_OPENED", payload: state });
    }
}