import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import TheModal from "../../components/TheModal/TheModal";
import If from "../If/If";

import AssetAppleStore from "../../assets/images/app-store.png";
import AssetPlayStore from "../../assets/images/google-play.png";
import AssetArrowRight from "../../assets/images/arrow-pink-right.png";
import AssetArrowUp from "../../assets/images/arrow-pink-up.png"

import {
    setTermosCondicoesModalOpened,
    setPoliticaPrivacidadeModalOpened,
    setFAQModalOpened
} from "../../redux/actions/TheModalActions";
import PerguntasFrequentes from "../PerguntasFrequentes/PerguntasFrequentes";

import "./TheFooter.scss";

export class TheFooter extends Component {
    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal(modal) {
        switch (modal) {
            case 'setFAQModalOpened': this.props.setFAQModalOpened(true); break
            case 'setTermosCondicoesModalOpened': this.props.setTermosCondicoesModalOpened(true); break
            case 'setPoliticaPrivacidadeModalOpened': this.props.setPoliticaPrivacidadeModalOpened(true); break
            default: break
        }
    }

    hideModal(modal) {
        switch (modal) {
            case 'setFAQModalOpened': this.props.setFAQModalOpened(false); break
            case 'setTermosCondicoesModalOpened': this.props.setTermosCondicoesModalOpened(false); break
            case 'setPoliticaPrivacidadeModalOpened': this.props.setPoliticaPrivacidadeModalOpened(false); break
            default: break
        }
    }

    changeChevron(card, chevron) {
        setTimeout(() => {
            if ($(card).attr("aria-expanded") === "true") {
                $(chevron).addClass("opened");
                $(chevron).removeClass("closed");
                $(chevron).attr("src", AssetArrowUp);
            } else {
                $(chevron).addClass("closed");
                $(chevron).removeClass("opened");
                $(chevron).attr("src", AssetArrowRight);
            }
        }, 500);
    }

    render() {
        return (
            <footer className="the-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-5 col-md-4">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <a href="https://apps.apple.com/br/app/clube-de-revistas/id1117256234?l=en" target="_blank" className="the-footer-app-store">
                                        <img src={ AssetAppleStore } alt=""/>
                                    </a>
                                </div>
                                <div className="col-12 col-md-6">
                                    <a href="https://play.google.com/store/apps/details?id=br.zed.clubederevistas" target="_blank">
                                        <img src={ AssetPlayStore } alt=""/>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-7 col-md-8">
                            <ul className="the-footer-links">
                                <li><a className="footer-links" onClick={() => this.showModal('setFAQModalOpened')}>Perguntas Frequentes (FAQ)</a></li>
                                <li><a className="footer-links" onClick={() => this.showModal('setTermosCondicoesModalOpened')}>Termos e condições</a></li>
                                <li><a className="footer-links" onClick={() => this.showModal('setPoliticaPrivacidadeModalOpened')}>Política de privacidade</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

const mapStateToProps = state => ({ 
    theFooterreducer: state.theFooterreducer
});
const mapDispatchToProps = dispatch => bindActionCreators({
    setFAQModalOpened,
    setTermosCondicoesModalOpened,
    setPoliticaPrivacidadeModalOpened
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TheFooter);