const INITIAL_STATE = { videoThumbnail: "", videoId: "", titulosMaisRecentes: [], modalVantagensLanding2: false };

export const reducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_LANDING_2_VANTAGENS_DO_CLUBE_VIDEO_THUMBNAIL":
            return { ...state, videoThumbnail: action.payload };
        case "SET_LANDING_2_VANTAGENS_DO_CLUBE_VIDEO_ID":
            return { ...state, videoId: action.payload };
        case "SET_VANTAGENS_DO_CLUBE_LANDING_2_TITULOS_MAIS_RECENTES":
            return { ...state, titulosMaisRecentes: action.payload };
        case "SET_MODAL_OPENED_VANTAGENS_CLUBE_LANDING_2":
            return { ...state, modalVantagensLanding2: action.payload };
        default:
            return state;
    }
}