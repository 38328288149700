import React from "react";

import Messages from "../../components/Messages/Messages";

const App = props => (
    <div data-testid="app">
        <div className="h-100">
            { props.children }
        </div>
        <Messages />
    </div>
);

export default App;