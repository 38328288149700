const INITIAL_STATE = { 
    FAQModalOpened: false,
    TermosCondicoesModalOpened: false,
    PoliticaPrivacidadeModalOpened: false,
};

export const reducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_FAQ_MODAL_OPENED":
            return { ...state, FAQModalOpened: action.payload }

        case "SET_TERMOS_CONDICOES_MODAL_OPENED":
            return { ...state, TermosCondicoesModalOpened: action.payload }

        case "SET_POLITICA_PRIVACIDADE_MODAL_OPENED":
            return { ...state, PoliticaPrivacidadeModalOpened: action.payload }

        default:
            return state;
    }
}