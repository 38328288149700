import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import AssetArrowRight from "../../assets/images/arrow-pink-right.png";
import AssetArrowUp from "../../assets/images/arrow-pink-up.png"

import Row from "../Row/Row";
import Grid from "../Grid/Grid";
import TheModal from "../TheModal/TheModal";
import { queryQuestions } from "../../redux/actions/PerguntasFrequentesActions";
import { setFAQModalOpened } from "../../redux/actions/TheModalActions";
import { setFaqOpened } from "../../redux/actions/Home";
import { setFaqModalOpened as setFaqModalOpenedHomeFooter } from "../../redux/actions/HomeFooterActions";

import "./PerguntasFrequentes.scss";

export class PerguntasFrequentes extends Component {
    constructor(props) {
        super(props);
        this.searchQuestions = this.searchQuestions.bind(this);
        this.hideModal = this.hideModal.bind(this);

        this.props.queryQuestions("");
    }

    hideModal() {
        this.props.setFAQModalOpened(false);
        this.props.setFaqOpened(false);
        this.props.setFaqModalOpenedHomeFooter(false);
    }

    searchQuestions(event) {
        this.props.queryQuestions(event.target.value);
    }

    changeChevron(card, chevron) {
        setTimeout(() => {
            if ($(card).attr("aria-expanded") === "true") {
                $(chevron).addClass("opened");
                $(chevron).removeClass("closed");
                $(chevron).attr("src", AssetArrowUp);
            } else {
                $(chevron).addClass("closed");
                $(chevron).removeClass("opened");
                $(chevron).attr("src", AssetArrowRight);
            }
        }, 500);
    }

    render() {
        const renderQueriedQuestions = questions => {
            const questionsList = questions || [];

            return questionsList.map((question, index) => (
                <div 
                    className="card"
                    key={`perguntas-frequentes-card-${index}`}
                    onClick={ () => this.changeChevron(`[data-target="#perguntas-frequentes-collapse-${index}"]`, `#perguntas-frequentes-chevron-${index}`) }
                >
                    <div 
                        className="card-header cursor-pointer" 
                        id={`perguntas-frequentes-heading-${index}`}
                    >
                        <div
                            data-toggle="collapse"
                            data-target={`#perguntas-frequentes-collapse-${index}`}
                            aria-controls={`perguntas-frequentes-collapse-${index}`}
                            key={`perguntas-frequentes-header-${index}`}
                        >
                            <Row className="no-gutters">
                                <Grid 
                                    cols="10"
                                    className="no-gutters d-flex align-items-center justify-content-start"
                                >
                                    <h5 className="f-montserrat f-w-600 f-18">
                                        { question.titulo }
                                    </h5>
                                </Grid>
                                <Grid 
                                    cols="2"
                                    className="no-gutters d-flex align-items-center justify-content-end"
                                >
                                    <img 
                                        src={AssetArrowRight} 
                                        alt="Seta para cima"
                                        className="perguntas-frequentes-chevron"
                                        id={`perguntas-frequentes-chevron-${index}`}
                                    />
                                </Grid>
                            </Row>
                        </div>
                    </div>
                    <div
                        id={`perguntas-frequentes-collapse-${index}`}
                        className="collapse"
                        aria-labelledby={`perguntas-frequentes-heading-${index}`}
                        data-parent="#perguntas-frequentes-accordion"
                    >
                        <div className="card-body">
                            <div 
                                className="f-lato f-w-400 text-grey f-16"
                                dangerouslySetInnerHTML={{__html: question.conteudo}}
                            >
                            </div>
                        </div>
                    </div>
                </div>
            ));
        }

        return (
            <TheModal>
                <button className="the-modal-close" onClick={ this.hideModal }>X</button>
                <div id="perguntas-frequentes">
                    <h2 className="f-montserrat f-w-500 f-24 f-l-h-33-6 text-black">
                        Perguntas <span className="text-pink f-w-700">frequentes</span>
                    </h2>
                    <Row className="m-b-24">
                        <Grid cols="12 12 12 7">
                            <input 
                                type="text" 
                                placeholder="Digite sua pergunta" 
                                name="question"
                                onChange={ this.searchQuestions }
                            />
                        </Grid>
                        <Grid 
                            cols="12 12 12 5"
                            className="d-flex align-items-center justify-content-center fale-conosco-col-desktop"
                        >
                            <span className="f-16">Não encontrou o que procurava? <a href="mailto:contato@clubederevistas.com" className="link-default m-l-3 m-t-10"> Fale conosco</a></span>
                        </Grid>
                    </Row>
                    <div 
                        id="perguntas-frequentes-accordion"
                        className="accordion"
                    >
                        { renderQueriedQuestions(this.props.perguntasFrequentesReducer.queriedQuestions) }
                    </div>
                    <span className="f-16 fale-conosco-col-mobile">Não encontrou o que procurava? <a href="#" className="link-default m-l-3 m-t-10"> Fale conosco</a></span>
                </div>
            </TheModal>
        )
    }
}

const mapStateToProps = state => ({
    perguntasFrequentesReducer: state.perguntasFrequentesReducer,
    HomeReducer: state.HomeReducer
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
    queryQuestions,
    setFAQModalOpened,
    setFaqOpened,
    setFaqModalOpenedHomeFooter
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PerguntasFrequentes);