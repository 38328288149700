import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TheButton from '../../components/TheButton/TheButton'

import AssetAppleStore from "../../assets/images/app-store.png"
import AssetPlayStore from "../../assets/images/google-play.png"

export class AlertAssinaturaDone extends Component {
    goToLogin() {
        window.open('https://clubederevistas.com/login.php', '_blank').focus();
    }

    render() {
        return (
            <section className="alert-assinatura-done">
                <div className="row m-b-20">
                    <div className="col-12 text-center">
                        <h4 className="f-24 f-w-700 text-black f-montserrat"><span className="text-pink">Bem-vindo</span> ao Clube!</h4>
                    </div>
                </div>

                <div className="row m-b-25">
                    <div className="col-12 text-center">
                        <p className="text-silver f-16">A partir de agora, você poderá acessar inúmeras edições entre mais de 120 títulos de revistas em seu computador, smartphone ou tablet. </p>
                    </div>
                </div>

                <div className="row m-b-30">
                    <div className="col-12 text-center">
                        <TheButton 
                        text="Acessar agora"
                        className="bg-pink btn-md text-white"
                        onClick={ () => this.goToLogin() } />
                    </div>
                </div>

                <div className="row m-b-25">
                    <div className="col-12 text-center">
                        <p className="text-silver">Ou <span className="text-black">baixe nosso aplicativo</span></p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6 text-right">
                        <a href="https://apps.apple.com/br/app/clube-de-revistas/id1117256234?l=en" target="_blank" className="the-footer-app-store">
                            <img src={ AssetAppleStore } alt="" className="w-120" />
                        </a>
                    </div>
                    <div className="col-6">
                        <a href="https://play.google.com/store/apps/details?id=br.zed.clubederevistas" target="_blank">
                            <img src={ AssetPlayStore } alt="" className="w-120" />
                        </a>
                    </div>
                </div>
            </section>
        )
    }
}

export default AlertAssinaturaDone