const INITIAL_STATE = {
    validLogin: false
}

export const reducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_VALID_LOGIN":
            return { ...state, validLogin: action.payload };
        default:
            return state;
    }
}