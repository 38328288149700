import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import "./LancamentosLanding1.scss";

import assetCcElo from "../../assets/images/credit-card-elo.png"
import assetCcAmericanExpress from "../../assets/images/credit-card-american-express.png"
import assetCcMaster from "../../assets/images/credit-card-master.png"
import assetCcVisa from "../../assets/images/credit-card-visa.png"
import leftChevron from "../../assets/images/left-chevron.png";
import rightChevron from "../../assets/images/right-chevron.png";
import AssetArrowWhiteDown from "../../assets/images/arrow-white-down.png";

import SlickCarousel from "../../components/SlickCarousel/SlickCarousel";
import Row from "../../components/Row/Row";
import Grid from "../../components/Grid/Grid"
import { getLancamentos } from "../../redux/actions/LancamentosLanding1Actions";

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
        {...props}
        className={
            "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
        type="button"
    >
        <img 
            src={leftChevron} 
            alt="Seta para a esquerda"
        />
    </button>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
        {...props}
        className={
            "slick-next slick-arrow" + (currentSlide === slideCount - 1 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
        type="button"
    >
        <img 
            src={rightChevron} 
            alt="Seta para a esquerda"
        />
    </button>
);

class LancamentosLanding1 extends Component {
    async componentDidMount() {
        await this.props.getLancamentos();
    }

    scrollTo(element) {
        const scrollTop = $(element).offset().top;
        window.scrollTo(0, scrollTop);
    }

    render() {

        return (
            <section id="lancamentos-l1" className="lancamentos-l1">
                <article className="lancamentos-l1-slide-container">
                    <div className="lancamentos-l1-text">
                        <div className="lancamentos-l1-text-content">
                            <h4 className="lancamentos-l1-slide-title f-montserrat f-w-400 text-black"> 
                                <span className="text-pink f-w-700">Últimos</span> lançamentos
                            </h4>
                        </div>
                    </div>
                    <div className="lancamentos-l1-slide">
                        <div className="d-none d-xl-block">
                            <SlickCarousel
                                dots={false}
                                infinite={true}
                                slidesToShow={3}
                                slidesToScroll={1}
                                autoplay={false}
                                speed={500}
                                autoplaySpeed={0}
                                cssEase={"linear"}
                                arrows={true}
                                initialSlide={2}
                                nextArrow={<SlickArrowRight />}
                                prevArrow={<SlickArrowLeft />}
                                centerMode={true}
                            >
                                { this.props.lancamentosLanding1Reducer.lancamentos.map((lancamento, index) => (
                                    <div 
                                        className="slide-item-magazine"
                                        key={`LancamentosLanding1-1-${index}`}
                                    >
                                        <div 
                                            className="d-flex flex-column justify-content-center align-items-center"
                                        >
                                            <img src={lancamento.image} alt={lancamento.imageAlt} />
                                            <div className="f-lato f-w-600 bg-white lancamento-slide-info p-24 d-flex justify-flex-start align-flex-center flex-column">
                                                <span className="f-14 f-l-h-22-4 text-grey">{`${lancamento.code} | ${lancamento.date}`}</span>
                                                <span className="f-18 f-l-h-28-8 text-black">{`${lancamento.title}`}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </SlickCarousel>
                        </div>
                        <div className="d-block d-xl-none">
                            <SlickCarousel
                                dots={false}
                                infinite={true}
                                slidesToShow={1}
                                slidesToScroll={1}
                                autoplay={false}
                                speed={500}
                                autoplaySpeed={0}
                                cssEase={"linear"}
                                arrows={true}
                                initialSlide={2}
                                centerMode={true}
                                centerPadding={"60px"}
                            >
                                { this.props.lancamentosLanding1Reducer.lancamentos.map((lancamento, index) => (
                                    <div 
                                        className="slide-item-magazine"
                                        key={`LancamentosLanding1-2-${index}`}
                                    >
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <img src={lancamento.image} alt={lancamento.imageAlt} />
                                            <div className="f-lato f-w-600 bg-white lancamento-slide-info p-24 d-flex justify-flex-start align-flex-center flex-column">
                                                <span className="f-14 f-l-h-22-4 text-grey">{`${lancamento.code} | ${lancamento.date}`}</span>
                                                <span className="f-18 f-l-h-28-8 text-black">{`${lancamento.title}`}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </SlickCarousel>
                        </div>
                    </div>
                </article>
                
                <article>
                    <div className="container">
                        <div className="lancamentos-l1-assine">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="lancamentos-l1-assine-cartoes">
                                        <img src={assetCcMaster} alt="Logo da Master Card" />
                                        <img src={assetCcVisa} alt="Logo da Visa" />
                                        <img src={assetCcElo} alt="Logo da Elo" />
                                        <img src={assetCcAmericanExpress} alt="Logo da American Express" />
                                    </div>
                                </div>

                                <div className="col-12 col-md-8">
                                    <h4 className="m-t-25">Comece a ler <span className="text-pink f-w-700">agora mesmo!</span></h4>
                                    <p className="f-16 text-silver">Basta cadastrar seu e-mail e realizar o pagamento via cartão de crédito. Sua assinatura é liberada logo após a aprovação do pagamento.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lancamentos-l1-arrow-down">
                        <a
                            href="#faca-parte-do-clube"
                        >
                            <img 
                                src={AssetArrowWhiteDown} 
                                alt="Seta para baixo"
                            />
                        </a>
                    </div>
                </article>
            </section>
        );
    }
}

const mapStateToProps = state => ({ lancamentosLanding1Reducer: state.lancamentosLanding1Reducer });
const mapDispatchToProps = dispatch => bindActionCreators({ getLancamentos }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LancamentosLanding1);