const buildNumber = (valueOnlyNumbers, firstSequence, secondSequence, thirdSequence, fourthSequence) => {
    let buildedNumber = "";

    if (valueOnlyNumbers.length < 3) buildedNumber = `${firstSequence}`;
    else if (valueOnlyNumbers.length === 3) buildedNumber = `${firstSequence}.`;
    else if (valueOnlyNumbers.length < 6) buildedNumber = `${firstSequence}.${secondSequence}`;
    else if (valueOnlyNumbers.length === 6) buildedNumber = `${firstSequence}.${secondSequence}.`;
    else if (valueOnlyNumbers.length < 9) buildedNumber = `${firstSequence}.${secondSequence}.${thirdSequence}`;
    else if (valueOnlyNumbers.length === 9) buildedNumber = `${firstSequence}.${secondSequence}.${thirdSequence}.`;
    else if (valueOnlyNumbers.length > 9) buildedNumber = `${firstSequence}.${secondSequence}.${thirdSequence}-${fourthSequence}`;

    return buildedNumber;
}

const normalizeCreditCardCPF = (value, prevValue) => {
    if (!value) return value;

    const valueOnlyNumbers = value.replace(/[^\d]/g, '');
    const prevValueOnlyNumbers = prevValue && prevValue.replace(/[^\d]/g, '');

    if (valueOnlyNumbers.length > 11 || value.length > 14) return prevValue;
    if (valueOnlyNumbers === prevValueOnlyNumbers) return value;

    const firstSequence = valueOnlyNumbers.slice(0, 3);
    const secondSequence = valueOnlyNumbers.slice(3, 6);
    const thirdSequence = valueOnlyNumbers.slice(6, 9);
    const fourthSequence = valueOnlyNumbers.slice(9, 11);

    return buildNumber(valueOnlyNumbers, firstSequence, secondSequence, thirdSequence, fourthSequence)
}

export default normalizeCreditCardCPF;