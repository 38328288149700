const INITIAL_STATE = { 
    menuColor: 'transparent',
    categories: [],
    tempCategories: [],
    capasRevistas: [],
};

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_MENU_COLOR":
            
            let menuColor = 'transparent'

            switch (action.payload) {
                case 'inicio': menuColor = 'transparent'; break
                case 'vantagens-do-clube': menuColor = 'white'; break
                case 'como-funciona': menuColor = 'white'; break
                case 'ultimas-edicoes': menuColor = 'white'; break
                case 'faca-parte-do-clube': menuColor = 'white'; break
                default: menuColor = 'white'; break
            }

            return { 
                ...state,
                menuColor: menuColor
            }
        
        case "SET_CATEGORIES":
            return { ...state, categories: action.payload }

        case "SET_TEMP_CATEGORIES":
            return { ...state, tempCategories: action.payload }

        case "SET_CAPAS_REVISTAS":
            return { ...state, capasRevistas: action.payload }

        default:
            return state;
    }
}