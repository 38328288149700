export const queryQuestions = (query) => {
    return dispatch => {
        fetch("data.json", { headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }})
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(async data => {
            if (data &&
                data["common"] &&
                data["common"]["perguntas-frequentes"] &&
                data["common"]["perguntas-frequentes"].length > 0)
            {
                let queriedQuestions = data["common"]["perguntas-frequentes"];

                if (query) {
                    const regex = new RegExp(query, "i");
                    queriedQuestions = await data["common"]["perguntas-frequentes"].filter(value => regex.test(value.titulo));
                }

                dispatch({ type: "SET_QUERIED_QUESTIONS", payload: queriedQuestions });
            }
        });
    }
}