const normalizeCreditCVC = (value, prevValue) => {
    if (!value) return value;

    const valueOnlyNumbers = value.replace(/[^\d]/g, '');
    const prevValueOnlyNumbers = prevValue && prevValue.replace(/[^\d]/g, '');

    if (valueOnlyNumbers.length > 4 || value.length > 4) return prevValue;
    if (valueOnlyNumbers === prevValueOnlyNumbers) return value;
    if (valueOnlyNumbers.length > 4) return prevValue;
    else return valueOnlyNumbers;
}

export default normalizeCreditCVC;