import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import App from "./containers/App/App";
//import { LandingPage1 } from "./containers/LandingPage1/LandingPage1";
import { LandingPage3 } from "./containers/LandingPage3/LandingPage3";
//import LandingPage2 from "./containers/LandingPage2/LandingPage2";
//import Home from "./containers/Home/Home";

const Routes = props => (
    <BrowserRouter>
        <Switch>
            <App>
                <Route path="/" component={LandingPage3} />
            </App>
        </Switch>
    </BrowserRouter>
);

export default Routes;