export const getApiErrorMessage = async errorCode => {
    return await fetch("data.json", { headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    }})
    .then(response => {
        if (response.status === 200) return response.json();
    })
    .then(data => {
        if (data["common"] && 
            data["common"]["erros-api-zed"])
        {
            return data["common"]["erros-api-zed"][errorCode];
        }
    })
}