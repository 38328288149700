export const setMenuColor = state => {
    return dispatch => {
        dispatch({ type: "SET_MENU_COLOR", payload: state });
    };
}

export const getCategoriesFromJson = () => {
    return dispatch => {
        fetch("data.json", { 
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(data => {
            dispatch({ type: "SET_CATEGORIES", payload: data.common.categories });
        })
    }
}

export const getCapasRevistasFromJson = (category) => {
    return async dispatch => {
        fetch("data.json", { headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }})
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(async data => {
            const { categories } = data.common;

            const capasRevistas = [];

            const imageRequired = await require(`../../assets/images/capa-revistas/istoe-dinheiro/1.jpg`);
            capasRevistas.push(imageRequired.default)

            // await categories.forEach(async (category) => {
            //     await category.subcategorias.forEach(async (sub) => {
            //         await sub.capaRevistas.forEach(async (image) => {
            //             console.log(image)

            //             const imageRequired = await require(`../../assets/images/capa-revistas/${image}`);
            //             console.log(imageRequired)

            //             capasRevistas.push(imageRequired.default);
            //         })
            //     })
            // })

            dispatch({ type: "SET_CAPAS_REVISTAS", payload: capasRevistas });
        })
    }
}

export const setTempCategories = categories => {
    return dispatch => {
        dispatch({ type: "SET_TEMP_CATEGORIES", payload: categories });
    };
}