const INITIAL_STATE = { 
    enableButtonCadastrar: false,
    enableButtonConcluir: false,
    submitLoading: false,
    formError: false,
    formErrorMessage: "",
    recaptchaToken: "",
    termosCondicoesModalOpened: false,
    politicaPrivacidadeModalOpened: false
};

export const reducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_ENABLE_BUTTON_CADASTRAR":
            return { ...state, enableButtonCadastrar: action.payload };
        case "SET_ENABLE_BUTTON_CONCLUIR":
            return { ...state, enableButtonConcluir: action.payload };
        case "SET_FORM_LANDING3_SUBMIT_LOADING":
            return { ...state, submitLoading: action.payload };
        case "SET_FORM_LANDING3_RECAPTCHA_TOKEN":
            return { ...state, recaptchaToken: action.payload };
        case "SET_FORM_ERROR":
            return { ...state, formError: action.payload };
        case "SET_FORM_ERROR_MESSAGE":
            return { ...state, formErrorMessage: action.payload };
        case "SET_ASSINATURA_LANDING_3_TERMOS_CONDICOES_MODAL_OPENED":
            return { ...state, termosCondicoesModalOpened: action.payload };
        case "SET_ASSINATURA_LANDING_3_POLITICA_PRIVACIDADE_MODAL_OPENED":
            return { ...state, politicaPrivacidadeModalOpened: action.payload };
        default:
            return state;
    }
}