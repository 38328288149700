export const getVideo = () => {
    return dispatch => {
        fetch("data.json", { headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }})
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(async data => {
            if (data["home"] && 
                data["home"]["faca-parte-do-clube"] &&
                data["home"]["faca-parte-do-clube"]["video"] &&
                data["home"]["faca-parte-do-clube"]["video"]["thumbnail"] && 
                data["home"]["faca-parte-do-clube"]["video"]["id"])
            {
                const imageName = data["home"]["faca-parte-do-clube"]["video"]["thumbnail"];
                const imageRequired = await require(`../../assets/images/home/${imageName}`);
                const imageRequiredDefault = imageRequired.default;
                const videoId = data["home"]["faca-parte-do-clube"]["video"]["id"];

                dispatch({ type: "SET_HOME_FACA_PARTE_VIDEO_THUMBNAIL", payload: imageRequiredDefault });
                dispatch({ type: "SET_HOME_FACA_PARTE_VIDEO_ID", payload: videoId });
            }
        });
    }
}

export const setVideoModalOpened = state => {
    return dispatch => {
        dispatch({ type: "SET_HOME_FACA_PARTE_VIDEO_MODAL_OPENED", payload: state });
    }
}