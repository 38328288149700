const buildNumber = (valueOnlyNumbers, firstSequence, secondSequence, thirdSequence, fourthSequence) => {
    let buildedNumber = "";

    if (valueOnlyNumbers.length < 4) buildedNumber = `${firstSequence}`;
    else if (valueOnlyNumbers.length === 4) buildedNumber = `${firstSequence}.`;
    else if (valueOnlyNumbers.length < 8) buildedNumber = `${firstSequence}.${secondSequence}`;
    else if (valueOnlyNumbers.length === 8) buildedNumber = `${firstSequence}.${secondSequence}.`;
    else if (valueOnlyNumbers.length < 12) buildedNumber = `${firstSequence}.${secondSequence}.${thirdSequence}`;
    else if (valueOnlyNumbers.length === 12) buildedNumber = `${firstSequence}.${secondSequence}.${thirdSequence}.`;
    else if (valueOnlyNumbers.length > 12) buildedNumber = `${firstSequence}.${secondSequence}.${thirdSequence}.${fourthSequence}`;

    return buildedNumber;
}

const normalizeCreditCardNumber = (value, prevValue) => {
    if (!value) return value;

    const valueOnlyNumbers = value.replace(/[^\d]/g, '');
    const prevValueOnlyNumbers = prevValue && prevValue.replace(/[^\d]/g, '');

    if (valueOnlyNumbers.length > 16 || value.length > 19) return prevValue;
    if (valueOnlyNumbers === prevValueOnlyNumbers) return value;

    const firstSequence = valueOnlyNumbers.slice(0, 4);
    const secondSequence = valueOnlyNumbers.slice(4, 8);
    const thirdSequence = valueOnlyNumbers.slice(8, 12);
    const fourthSequence = valueOnlyNumbers.slice(12, 16);

    return buildNumber(valueOnlyNumbers, firstSequence, secondSequence, thirdSequence, fourthSequence)
}

export default normalizeCreditCardNumber;