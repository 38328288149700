import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import "./VantagensDoClubeLanding1.scss";

import advantagesIcon1 from "../../assets/images/landing-page-1/advantages-icon1.png";
import advantagesIcon2 from "../../assets/images/landing-page-1/advantages-icon2.png";
import advantagesIcon3 from "../../assets/images/landing-page-1/advantages-icon3.png";
import advantagesIcon4 from "../../assets/images/landing-page-1/advantages-icon4.png";
import advantagesDevices from "../../assets/images/landing-page-1/advantages-devices.png";
import advantagesDevicesMobile from "../../assets/images/landing-page-1/advantages-devices-mobile.png";
import assetArrowPinkDown from "../../assets/images/arrow-pink-down.png";

import IconEllipse from "../IconEllipse/IconEllipse";
import Row from "../Row/Row";
import Grid from "../Grid/Grid";
import TheButton from "../TheButton/TheButton"; 
import SlickCarousel from "../SlickCarousel/SlickCarousel";
import { setModal1Opened, getVideoUrl, getPrincipaisTitulosImages } from "../../redux/actions/VantagensDoClubeLanding1Actions";

export class VantagensDoClubeLanding1 extends Component {
    constructor(props) {
        super(props);

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);

        this.props.getVideoUrl();
        this.props.getPrincipaisTitulosImages();

        this.state = {
            isMobile: false,
        }
    }

    async componentDidMount() {
        await this.props.getPrincipaisTitulosImages();
    }

    showModal() {
        this.props.setModal1Opened(true);
    }

    hideModal() {
        this.props.setModal1Opened(false);
    }

    scrollTo(element) {
        const scrollTop = $(element).offset().top;
        window.scrollTo(0, scrollTop);
    }

    componentDidMount() {
        if( /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            this.setState({
                isMobile: true
            })
        }
    }

    render() {
        const carouselItems = [
            (
                <div>
                    <div className="d-flex flex-row align-items-center m-b-12">
                        <IconEllipse image={advantagesIcon1} alt="Ícone de livro" className="" />
                        <h4 className="f-l-h-22-4 f-16 text-black f-montserrat f-w-500 p-l-16">
                        Tudo o que você precisa em um só lugar
                        </h4>
                    </div>
                    <div className="d-flex flex-row">
                        <p className="f-lato f-w-400 f-14 f-l-h-22-4 text-grey m-b-56">
                        Fique sempre bem-informado! Atualidades, negócios, estilo de vida, celebridades, esportes, receitas, viagens, conteúdo para crianças e muito mais!
                        </p>
                    </div>
                </div>
            ),
            (
                <div>
                    <div className="d-flex flex-row align-items-center m-b-12">
                        <IconEllipse image={advantagesIcon2} alt="Ícone de livro" className="" />
                        <h4 className="f-l-h-22-4 f-16 text-black f-montserrat f-w-500 p-l-16">
                        Do seu jeito, no seu tempo
                        </h4>
                    </div>
                    <div className="d-flex flex-row">
                        <p className="f-lato f-w-400 f-14 f-l-h-22-4 text-grey m-b-56">
                        Marque as páginas durante a leitura, favorite as revistas que quiser e volte a ler onde parou!  
                        </p>
                    </div>
                </div>
            ),
            (
                <div>
                    <div className="d-flex flex-row align-items-center m-b-12">
                        <IconEllipse image={advantagesIcon3} alt="Ícone de livro" className="" />
                        <h4 className="f-l-h-22-4 f-16 text-black f-montserrat f-w-500 p-l-16">
                        Leia onde e quando quiser 
                        </h4>
                    </div>
                    <div className="d-flex flex-row">
                        <p className="f-lato f-w-400 f-14 f-l-h-22-4 text-grey m-b-56">
                        Leia pelo celular, tablet ou computador. Você escolhe! E baixe sua revista favorita para ler quando estiver sem conexão com a Internet.
                        </p>
                    </div>
                </div>
            ),
            (
                <div>
                    <div className="d-flex flex-row align-items-center m-b-12">
                        <IconEllipse image={advantagesIcon4} alt="Ícone de livro" className="" />
                        <h4 className="f-l-h-22-4 f-16 text-black f-montserrat f-w-500 p-l-16">
                        Cancele a qualquer momento
                        </h4>
                    </div>
                    <div className="d-flex flex-row">
                        <p className="f-lato f-w-400 f-14 f-l-h-22-4 text-grey m-b-56">
                        Escolha a assinatura mensal e cancele quando quiser ou garanta sua assinatura por 1 ano e ganhe um desconto especial!
                        </p>
                    </div>
                </div>
            )
        ];

        return (
            <section id="club-advantages-landing-1" className="club-advantages-landing-1">
                <div>
                    <div>

                        {/* DESKTOP */}
                        <div className="d-none d-md-block">
                            <div className="container">
                                <Row>
                                    <Grid cols="12" className="text-center m-t-100 m-b-46 m-t-60">
                                        <h2><span className="text-pink">Vantagens</span> do clube</h2>
                                    </Grid> 
                                </Row>

                                <Row>
                                    <Grid cols="4">
                                        <IconEllipse image={advantagesIcon1} alt="Ícone de livro" className="justify-flex-end m-b-16" />
                                        <h4 className="f-l-h-28 f-20 text-black f-montserrat f-w-600 text-right">
                                            <span className="d-block">Tudo o que você precisa </span>
                                            <span className="d-block">em um só lugar </span>
                                        </h4>
                                        <p className="f-lato f-w-400 f-16 f-l-h-25-6 text-grey text-right m-b-56">
                                            <span className="d-block">Fique sempre bem-informado! Atualidades, negócios, </span>
                                            <span className="d-block">estilo de vida, celebridades, esportes, receitas, </span>
                                            <span className="d-block">viagens, conteúdo para crianças e muito mais!</span>
                                        </p>
                                        <IconEllipse image={advantagesIcon3} alt="Ícone de livro" className="justify-flex-end m-b-16" />
                                        <h4 className="f-l-h-28 f-20 text-black f-montserrat f-w-600 text-right">
                                            <span className="d-block">Do seu jeito,</span>
                                            <span className="d-block">no seu tempo</span>
                                        </h4>
                                        <p className="f-lato f-w-400 f-16 f-l-h-25-6 text-grey text-right m-b-56">
                                            <span className="d-block">Marque as páginas durante a leitura, favorite</span> 
                                            <span className="d-block"> as revistas que quiser e volte</span>
                                            <span className="d-block"> a ler onde parou!</span>
                                        </p>
                                    </Grid>
                                    <Grid cols="4" className="d-flex flex-column align-items-center">
                                        <img src={advantagesDevices} 
                                            alt="Dispositivos móveis" 
                                            className="w-100 flex-grow-0"/>
                                        <TheButton path="#" 
                                            text="Assista ao vídeo e saiba mais"
                                            className="bg-pink btn-md text-white flex-grow-1"
                                            onClick={ this.showModal }/>
                                    </Grid>
                                    <Grid cols="4">
                                        <IconEllipse image={advantagesIcon2} alt="Ícone de livro" className="m-b-16" />
                                        <h4 className="f-l-h-28 f-20 text-black f-montserrat f-w-600 text-left">
                                            <span className="d-block">Leia onde e</span>
                                            <span className="d-block">quando quiser </span>
                                        </h4>
                                        <p className="f-lato f-w-400 f-16 f-l-h-25-6 text-grey text-left m-b-56">
                                            <span className="d-block">Leia pelo celular, tablet ou computador.</span> 
                                            <span className="d-block">Você escolhe! E baixe sua revista favorita</span>
                                            <span className="d-block"> para ler quando estiver sem conexão com a Internet.</span>
                                        </p>
                                        <IconEllipse image={advantagesIcon4} alt="Ícone de livro" className="m-b-16" />
                                        <h4 className="f-l-h-28 f-20 text-black f-montserrat f-w-600 text-left">Cancele a qualquer momento</h4>
                                        <p className="f-lato f-w-400 f-16 f-l-h-25-6 text-grey text-left">
                                            <span className="d-block">Escolha a assinatura mensal e cancele</span> 
                                            <span className="d-block">quando quiser ou garanta sua assinatura</span> 
                                            <span className="d-block">por 1 ano e ganhe um desconto especial!</span> 
                                        </p>
                                    </Grid>
                                </Row>

                                <Row className="m-b-40 m-t-50">
                                    <Grid cols="12" className="d-flex justify-content-center align-items-center">
                                        <h3 className="f-24 f-l-h-22-4">
                                            <span className="f-w-700 text-pink">As melhores revistas do Brasil</span> estão aqui!
                                        </h3>
                                    </Grid>
                                </Row>
                            </div>
                            
                            <div className="container-fluid">
                                <Row>
                                    <Grid cols="12">
                                        <SlickCarousel
                                            dots={false}
                                            infinite={true}
                                            slidesToShow={7} 
                                            slidesToScroll={1}
                                            autoplay={true}
                                            speed={3000}
                                            autoplaySpeed={0}
                                            arrows={false}
                                            cssEase={"linear"}
                                        >
                                            { this.props.vantagensDoClubeLanding1reducer.principaisTitulosImages.map((image, index) => (
                                                <div 
                                                    key={`VantagensDoClubeLanding1-1-${index}`}
                                                    className="item d-flex justify-content-center align-items-center"
                                                >

                                                    {/* <code>{JSON.stringify(image)}</code> */}

                                                    <img
                                                        className="w-50-p-i"
                                                        key={`ClubAdvantagensLanding1-1-image-${index}`}
                                                        src={image}
                                                        alt="Logo dos principais títulos do Brasil"
                                                    />
                                                </div>
                                            ))}
                                        </SlickCarousel>
                                    </Grid>
                                </Row>
                            </div>
                            <div className="club-advantages-landing-1-arrow-down cursor-pointer">
                                <a
                                    href="#como-funciona"
                                >
                                    <img 
                                        src={assetArrowPinkDown} 
                                        alt="Seta para baixo"
                                        onClick={ () => this.scrollTo("#como-funciona-l1") }
                                    />
                                </a>
                            </div>
                        </div>
                        
                        {/* MOBILE */}
                        <div className="d-block d-md-none">
                            <div className="container">
                                <Row>
                                    <Grid cols="12" className="text-center m-t-100 m-b-30">
                                        <h2 className="f-20"><span className="text-pink">Vantagens</span> do clube</h2>
                                    </Grid>
                                </Row>

                                <Row className="m-b-40">
                                    <Grid cols="12" className="d-flex flex-column align-items-center">
                                        <img src={advantagesDevicesMobile} 
                                            alt="Dispositivos móveis" 
                                            className="w-50 m-b-30 dispositivos-moveis-image"
                                        />
                                        <SlickCarousel
                                            dots={true}
                                            infinite={false}
                                            slidesToShow={1}
                                            slidesToScroll={1}
                                            autoplay={false}
                                            speed={500}
                                            autoplaySpeed={0}
                                            cssEase={"linear"}
                                            arrows={false}
                                            initialSlide={2}
                                            centerMode={false}
                                            centerPadding={"60px"}
                                        >
                                            {carouselItems}
                                        </SlickCarousel>
                                        <TheButton path="#" 
                                            text="Assista ao vídeo e saiba mais"
                                            className="bg-pink btn-md text-white clube-vantagens-cta"
                                            onClick={ this.showModal }/>
                                    </Grid>
                                </Row>

                                <Row className="m-b-40">
                                    <Grid cols="12" className="d-flex justify-content-center align-items-center">
                                        <h3 className="f-16 f-l-h-22-4 text-center">
                                            <span className="f-w-700 text-pink">As melhores revistas do Brasil</span> estão aqui!
                                        </h3>
                                    </Grid>
                                </Row>

                                <Row>
                                    <Grid cols="12">
                                        <SlickCarousel
                                            dots={false}
                                            infinite={false}
                                            slidesToShow={3}
                                            slidesToScroll={1}
                                            autoplay={true}
                                            speed={3000}
                                            autoplaySpeed={0}
                                            cssEase={"linear"}
                                        >
                                            { this.props.vantagensDoClubeLanding1reducer.principaisTitulosImages.map((image, index) => (
                                                <div 
                                                    key={`VantagensDoClubeLanding1-2-${index}`}
                                                    className="item d-flex justify-content-center align-items-center"
                                                >
                                                    <img
                                                        className="w-70-p-i"
                                                        key={index}
                                                        src={image}
                                                        alt="Logo dos principais títulos do Brasil"
                                                    />
                                                </div>
                                            ))}
                                        </SlickCarousel>
                                    </Grid>
                                </Row>
                            </div>
                            <div className="club-advantages-landing-1-arrow-down cursor-pointer">
                                <a
                                    href="#como-funciona"
                                >
                                    <img 
                                        src={assetArrowPinkDown} 
                                        alt="Seta para baixo"
                                        onClick={ () => this.scrollTo("#como-funciona-l1") }
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => ({ vantagensDoClubeLanding1reducer: state.vantagensDoClubeLanding1reducer });
const mapDispatchToProps = dispatch => bindActionCreators({
    setModal1Opened, getVideoUrl, getPrincipaisTitulosImages
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(VantagensDoClubeLanding1);