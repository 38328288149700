import React, { Component } from "react";
import $ from "jquery";

import TheButton from '../../components/TheButton/TheButton'

import './BannerLanding1.scss'

import AssetArrowWhiteDown from "../../assets/images/arrow-white-down.png"
import Grid from "../Grid/Grid";
import Row from "../Row/Row";
import AssetBanner1Item1 from "../../assets/images/landing-page-1/banner-1-item-1.png";

export class BannerLanding1 extends Component {
    scrollTo(element) {
        const scrollTop = $(element).offset().top;
        window.scrollTo(0, scrollTop);
    }

    render () {
        return (
            <section id="banner-l1" className="banner-l1">
                <div className="text-orange-white banner-content-mobile">
                    <Row>
                        <Grid 
                            className="banner-l1-text"
                            cols="12 12 12 6"
                        >
                            <div className="banner-l1-text">
                                <h2 className="f-montserrat f-w-600">Uma banca <b>inteira</b> na sua mão</h2>
                                <h3 className="f-lato w-450">Leia as melhores revistas por R$ 12,99 ao mês.</h3>

                                <div className="banner-l1-cta d-block d-lg-none m-b-40">
                                    <a href="#faca-parte-do-clube">
                                        <TheButton 
                                            text="Faça parte do Clube"
                                            className="bg-white btn-lg text-pink"
                                        />
                                    </a>
                                    <span className="d-block m-t-20">
                                        Ou  <a href="#vantagens-do-clube"><span
                                            className="m-t-25 more-details-span" 
                                        >
                                            saiba mais detalhes 
                                        </span></a> 
                                        <a href="#vantagens-do-clube">
                                            <img 
                                                src={ AssetArrowWhiteDown } 
                                            />
                                        </a>
                                    </span>
                                </div>

                                <img 
                                    src={AssetBanner1Item1}
                                    alt="Telefone celular na mão"
                                    className="banner-l1-img-mobile m-b-24"
                                />
                                <div className="banner-l1-cta d-none d-lg-block">
                                    <a href="#faca-parte-do-clube">
                                        <TheButton 
                                            text="Faça parte do Clube"
                                            className="bg-white btn-lg text-pink"
                                        />
                                    </a>
                                    <span className="d-block m-t-20">
                                        Ou  <a href="#vantagens-do-clube"><span
                                            className="m-t-25 more-details-span" 
                                        >
                                            saiba mais detalhes 
                                        </span></a> 
                                        <a href="#vantagens-do-clube">
                                            <img 
                                                src={ AssetArrowWhiteDown } 
                                            />
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid 
                            className="banner-l1-img-desktop"
                            cols="12 12 12 6"
                        >
                            <img 
                                src={AssetBanner1Item1}
                                alt="Telefone celular na mão"
                            />
                        </Grid>
                    </Row>
                    <div className="banner-l1-arrow-down">
                        <a
                            href="#vantagens-do-clube"
                        >
                            <img 
                                src={AssetArrowWhiteDown} 
                                alt="Seta para baixo"
                                onClick={ () => this.scrollTo("#club-advantages-landing-1") }
                            />
                        </a>
                    </div>
                </div>
                <div className="text-orange-white banner-content-desktop container">
                    <Row>
                        <Grid 
                            className="banner-l1-text"
                            cols="12 12 12 6"
                        >
                            <div className="banner-l1-text">
                                <h2 className="f-montserrat f-w-600">Uma banca <b>inteira</b> na sua mão</h2>
                                <h3 className="f-lato w-450 m-b-30">Leia as melhores revistas por R$12,99 ao mês.</h3>
                                <img 
                                    src={AssetBanner1Item1}
                                    alt="Telefone celular na mão"
                                    className="banner-l1-img-mobile m-b-24"
                                />
                                <div className="banner-l1-cta">
                                    <a href="#faca-parte-do-clube">
                                        <TheButton 
                                            text="Faça parte do Clube"
                                            className="bg-white btn-lg text-pink"
                                        />
                                    </a>
                                    <span className="d-block m-t-20">
                                        Ou  <a href="#vantagens-do-clube"><span
                                            className="m-t-25 more-details-span" 
                                        >
                                            saiba mais detalhes 
                                        </span></a> 
                                        <a href="#vantagens-do-clube">
                                            <img 
                                                src={ AssetArrowWhiteDown } 
                                            />
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </Grid>
                    </Row>
                    <img
                        id="banner-1-item-1-img"
                        src={AssetBanner1Item1}
                        alt="Telefone celular na mão"
                    />
                    <div className="banner-l1-arrow-down">
                        <a
                            href="#vantagens-do-clube"
                        >
                            <img 
                                src={AssetArrowWhiteDown} 
                                alt="Seta para baixo"
                                onClick={ () => this.scrollTo("#club-advantages-landing-1") }
                            />
                        </a>
                    </div>
                </div>
            </section>
        );
    }
}

export default BannerLanding1;