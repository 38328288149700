export const setFaqOpened = state => {
    return dispatch => {
        dispatch({ type: "SET_FAQ_OPENED", payload: state });
    };
}

export const setLoginFormOpened = state => {
    return dispatch => {
        dispatch({ type: "SET_LOGIN_FORM_OPENED", payload: state });
    };
}

export const setEsqueciSenhaFormOpened = state => {
    return dispatch => {
        dispatch({ type: "SET_ESQUECI_SENHA_FORM_OPENED", payload: state });
    };
}