import React from "react";

import "./IconEllipse.scss";

const IconEllipse = props => (
    <div className={`icon-ellipse-container ${props.className}`}>
        <div className="icon-ellipse d-flex justify-content-center align-items-center">
            <img src={ props.image } alt={props.alt}/>
        </div>
    </div>
);

export default IconEllipse;