const INITIAL_STATE = { 
    MenuVantagensOpened: false,
    MenuComoFuncionaOpened: false,
    setMenuFacaParteOpened: false,
};

export const reducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_MENU_VANTAGENS_OPENED":
            return { ...state, MenuVantagensOpened: action.payload }

        case "SET_MENU_COMO_FUNCIONA_OPENED":
            return { ...state, MenuComoFuncionaOpened: action.payload }

        case "SET_MENU_FACA_PARTE_OPENED":
            return { ...state, MenuFacaParteOpened: action.payload }

        default:
            return state;
    }
}