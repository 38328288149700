import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field, change, reset } from "redux-form";
import $, { type } from "jquery";
import ReCAPTCHA from "react-google-recaptcha";

import "./AssinaturaFormLanding3.scss"

import If from "../If/If";
import AssetCcElo from "../../assets/images/credit-card-elo.png"
import AssetCcAmericanExpress from "../../assets/images/credit-card-american-express.png"
import AssetCcMaster from "../../assets/images/credit-card-master.png"
import AssetCcVisa from "../../assets/images/credit-card-visa.png"

import TheModal from "../TheModal/TheModal";

import normalizeCreditCardNumber from "../../redux/normalize/normalizeCreditCardNumber";
import normalizeCreditCardValidity from "../../redux/normalize/normalizeCreditCardValidity";
import normalizeCreditCardCVC from "../../redux/normalize/normalizeCreditCardCVC";
import normalizeCreditCardCPF from "../../redux/normalize/normalizeCreditCardCPF";
import { validateData } from "../../common/validateData";
import { getCreditCardFlag } from "../../common/getCreditCardFlag";
import { 
    setButtonCadastrarEnable,
    setButtonConcluirEnable,
    setRecaptchaToken,
    setSubmitLoading,
    setTermosCondicoesModalOpened,
    setPoliticaPrivacidadeModalOpened
} from "../../redux/actions/AssinaturaFormLanding3Actions";

import {
    setTermosCondicoesModalOpened as setTermosCondicoesModalOpenedLanding3,
    setPoliticaPrivacidadeModalOpened as setPoliticaPrivacidadeModalOpenedLanding3
} from "../../redux/actions/AssinaturaFormLanding3Actions";

import {
    setTermosCondicoesModalOpened as setTermosCondicoesModalOpenedLanding1,
    setPoliticaPrivacidadeModalOpened as setPoliticaPrivacidadeModalOpenedLanding1
} from "../../redux/actions/AssinaturaFormLanding1Actions";

import ReactGA from 'react-ga';

class AssinaturaFormLanding3 extends Component {    
    constructor(props) {
        super(props);

        this.validateFieldset1 = this.validateFieldset1.bind(this);
        this.validateFieldset2 = this.validateFieldset2.bind(this);

        this.validateFieldset1();

        this.state = {
            showModalTerms: false,
            showModalSuccess: false
        }
    }

    validateFieldset1() {
        const customerEmail = $("[name='customerEmail']").val();
        const termsAndConditionsOfUse = $("[name='termsAndConditionsOfUse']").is(":checked");
        const privacyPolicy = $("[name='privacyPolicy']").is(":checked");

        if (!validateData(customerEmail, "EMAIL")) {
            $("[name='customerEmail']").addClass("input-error");
            this.props.setButtonCadastrarEnable(false);
            return false;
        } else {
            $("[name='customerEmail']").removeClass("input-error");
        }

        if (!termsAndConditionsOfUse) {
            this.props.setButtonCadastrarEnable(false);
            return false;
        }

        if (!privacyPolicy) {
            this.props.setButtonCadastrarEnable(false);
            return false;
        }
        
        this.props.setButtonCadastrarEnable(true);
    }

    showModal(modal) {
        switch (modal) {
            case "setFAQModalOpened": this.props.setFAQModalOpened(false); break
            case "setTermosCondicoesModalOpened": this.props.setTermosCondicoesModalOpened(false); break
            case "setPoliticaPrivacidadeModalOpened": this.props.setPoliticaPrivacidadeModalOpened(false); break
            default: break
        }
    }

    async validateFieldset2(recaptchaToken) {
        const creditCardNumber = $("[name='creditCardNumber']").val();
        const creditCardValidity = $("[name='creditCardValidity']").val();
        const creditCardCVC = $("[name='creditCardCVC']").val();
        const creditCardHolder = $("[name='creditCardHolder']").val();
        const creditCardCPF = $("[name='creditCardCPF']").val();
        const acceptCharge  = $("[name='acceptCharge']").is(":checked");
        const recaptchaTokenFromState = this.props.assinaturaFormLanding3Reducer.recaptchaToken;

        const token_autentication = localStorage.setItem('token_autentication', recaptchaToken)

        if (!validateData(creditCardNumber, "CREDIT_CARD_NUMBER")) {
            $("[name='creditCardNumber']").addClass("input-error");
            $('.error-message-creditCardNumber').css("display", "block")
            this.props.setButtonConcluirEnable(false);
            return false;
        } else {
            $('.error-message-creditCardNumber').css("display", "none")
            $("[name='creditCardNumber']").removeClass("input-error");
        }

        const creditCardFlag = getCreditCardFlag(creditCardNumber);
        if (!creditCardFlag) {
            $("[name='creditCardNumber']").addClass("input-error");
            $('.error-message-creditCardNumber').css("display", "block")

            this.props.setButtonConcluirEnable(false);
            return false;
        } else {
            $("[name='creditCardNumber']").removeClass("input-error");
            $('.error-message-creditCardNumber').css("display", "none")
            this.props.change("creditCardFlag", creditCardFlag);
        }

        if (!validateData(creditCardValidity, "CREDIT_CARD_VALIDITY")) {
            $("[name='creditCardValidity']").addClass("input-error");
            $('.error-message-creditCardValidity').css("display", "block")
            this.props.setButtonConcluirEnable(false);
            return false;
        } else {
            $('.error-message-creditCardValidity').css("display", "none")
            $("[name='creditCardValidity']").removeClass("input-error");
        }

        if (!validateData(creditCardCVC, "CREDIT_CARD_CVC")) {
            $("[name='creditCardCVC']").addClass("input-error");
            $('.error-message-creditCardCVC').css("display", "block")
            this.props.setButtonConcluirEnable(false);
            return false;
        } else {
            $('.error-message-creditCardCVC').css("display", "none")
            $("[name='creditCardCVC']").removeClass("input-error");
        }

        if (!creditCardHolder) {
            $("[name='creditCardHolder']").addClass("input-error");
            $('.error-message-creditCardHolder').css("display", "block")
            this.props.setButtonConcluirEnable(false);
            return false;
        } else {
            $('.error-message-creditCardHolder').css("display", "none")
            $("[name='creditCardHolder']").removeClass("input-error");
        }

        if (!validateData(creditCardCPF, "CREDIT_CARD_CPF")) {
            $("[name='creditCardCPF']").addClass("input-error");
            $('.error-message-creditCardCPF').css("display", "block")
            this.props.setButtonConcluirEnable(false);
            return false;
        } else {
            $('.error-message-creditCardCPF').css("display", "none")
            $("[name='creditCardCPF']").removeClass("input-error");
        }

        if (!acceptCharge) {
            this.props.setButtonConcluirEnable(false);
            return false;
        }

        if (!recaptchaTokenFromState) {
            if (recaptchaToken && typeof(recaptchaToken) === "string") {
                await this.props.setRecaptchaToken(recaptchaToken);
                this.props.change("recaptchaToken", recaptchaTokenFromState);
            } else {
                this.props.setButtonConcluirEnable(false);
                return false;
            }
        } else {
            this.props.change("recaptchaToken", recaptchaTokenFromState);
        }

        this.props.setButtonConcluirEnable(true);
    }

    goToNextStep() {
        $(".text-zed").hide();
        $("#assinatura-form #email-info").fadeOut();
        $("#assinatura-form #credit-card-info").fadeIn();

        fetch("variables.json", { 
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(data => {
            const customerEmail = $("[name='customerEmail']").val();
            const urlParams = new URLSearchParams(window.location.search);

            ReactGA.event({
                category: 'CR-LP',
                action: 'InformarEmail',
                label: 'Usuário informou email e avançou para pagamento'
            });

            const body = {
                "email": customerEmail.trimEnd(),
                "plan_id": data.plan_id,
                "clickid": urlParams.get('clickid'),
            }

            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }

            fetch('https://ads.clubederevistas.com/api/email/index.php', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
        })
    }

    returnToPreviousStep() {
        $("#assinatura-form #email-info").fadeIn();
        $("#assinatura-form #credit-card-info").fadeOut();
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <form 
                id="assinatura-form"
                role="form"
            >

                <fieldset id="email-info">
                    <div className="row m-b-25">
                        <div className="col-12 d-flex">
                            <h4 className="assinatura-l1-form-title p-t-5">Informe seu e-mail</h4>
                        </div>
                    </div>

                    <div className="row m-b-25">
                        <div className="col-12"> 
                            <Field 
                                component="input"
                                name="customerEmail"
                                type="text"
                                placeholder="seunome@provedor.com"
                                onChange={ this.validateFieldset1 }
                            />
                            <span class="error-message error-message-customerEmail">Insira um email válido</span>
                        </div>
                    </div>

                    <div className="row m-b-10 d-none">
                        <div className="col-12">
                            <div className="checkbox-label">
                                <Field 
                                    component="input"
                                    name="termsAndConditionsOfUse"
                                    type="checkbox"
                                    onClick={ this.validateFieldset1 }
                                    checked
                                />
                                <label htmlFor="acceptCharge">
                                    Li e aceito os 
                                    <span 
                                        className="open-modal-text" 
                                        onClick={ () => this.props.setTermosCondicoesModalOpened(true) }
                                    > Termos e Condições de Uso</span>.
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="row m-b-25 d-none">
                        <div className="col-12">
                            <div className="checkbox-label">
                                <Field 
                                    component="input"
                                    name="privacyPolicy"
                                    type="checkbox"
                                    onClick={ this.validateFieldset1 }
                                    checked
                                />
                                <label htmlFor="acceptCharge">
                                    Li e aceito a
                                    <span 
                                        className="open-modal-text" 
                                        onClick={ () => this.props.setPoliticaPrivacidadeModalOpened(true) }
                                    > Política de Privacidade</span>.
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="row m-t-25">
                        <div className="col-12 text-center">
                            <button
                                className={`the-button bg-pink btn-md text-white${!this.props.assinaturaFormLanding3Reducer.enableButtonCadastrar ? " btn-disabled" : ""}`}
                                onClick={this.goToNextStep}
                                type="button"
                                disabled={ !this.props.assinaturaFormLanding3Reducer.enableButtonCadastrar }
                            >
                                QUERO LER!
                            </button>
                        </div>
                    </div>
                </fieldset>
                <fieldset id="credit-card-info">
                    <div className="row m-b-25">
                        <div className="col-12">
                            <div className="assinatura-l1-pagamento-title">
                                <h4 className="assinatura-l1-form-title p-t-5">1º mês grátis, depois só R$ 12,99/mês por 1 ano!</h4>
                                <div className="assinatura-l1-cards m-l-auto">
                                    <img src={ AssetCcMaster } />
                                    <img src={ AssetCcVisa } />
                                    <img src={ AssetCcElo } />
                                    <img src={ AssetCcAmericanExpress } />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-b-25">
                        <div className="col-12">
                            <label htmlFor="creditCardNumber">Número do cartão de crédito</label>
                            <Field 
                                component="input"
                                name="creditCardNumber"
                                type="text"
                                placeholder="0000.0000.0000.0000"
                                normalize={normalizeCreditCardNumber}
                                onChange={ this.validateFieldset2 }
                            />
                            <span class="error-message error-message-creditCardNumber">Insira um número de cartão de crédito válido.</span>
                        </div>
                    </div>
                    <div className="row m-b-25">
                        <div className="col-6">
                            <label htmlFor="creditCardValidity">Validade</label>
                            <Field 
                                component="input"
                                name="creditCardValidity"
                                type="text"
                                placeholder="00/0000"
                                normalize={normalizeCreditCardValidity}
                                onChange={ this.validateFieldset2 }
                            />
                            <span class="error-message error-message-creditCardValidity">Insira uma data de validade correta.</span>
                        </div>
                        <div className="col-6">
                            <label htmlFor="creditCardCVC">CVC</label>
                            <Field 
                                component="input"
                                name="creditCardCVC"
                                type="text"
                                placeholder="000"
                                normalize={normalizeCreditCardCVC}
                                onChange={ this.validateFieldset2 }
                            />
                            <span class="error-message error-message-creditCardCVC">Insira o código de 3 dígitos exibido no verso do cartão (Amex: 4 dígitos exibidos na frente)</span>
                        </div>
                    </div>
                    <div className="row m-b-25">
                        <div className="col-12">
                        <label htmlFor="creditCardHolder">Nome do titular (igual ao cartão)</label>
                            <Field
                                component="input"
                                name="creditCardHolder"
                                type="text"
                                placeholder="Digite o nome igual ao do cartão"
                                onChange={ this.validateFieldset2 }
                            />
                            <span class="error-message error-message-creditCardHolder">Insira o nome do titular como está no cartão</span>
                        </div>
                    </div>
                    <div className="row m-b-25">
                        <div className="col-12">
                            <label htmlFor="creditCardCPF">CPF do titular do cartão</label>
                            <Field
                                component="input"
                                name="creditCardCPF"
                                type="text"
                                placeholder="000.000.000-00"
                                normalize={normalizeCreditCardCPF}
                                onChange={ this.validateFieldset2 }
                            />
                            <span class="error-message error-message-creditCardCPF">Insira um número de CPF válido.</span>
                        </div>
                    </div>
                    <div className="row m-b-25">
                        <div className="col-12">
                            <div className="checkbox-label p-l-0">
                                <Field 
                                    component="input"
                                    name="acceptCharge"
                                    type="checkbox"
                                    onClick={ this.validateFieldset2 }
                                    className="d-none"
                                    checked
                                />
                                <label htmlFor="acceptCharge">
                                    Ao avançar, você concorda com a cobrança mensal de R$ 12,99 realizada neste cartão a partir de hoje. 
                                    Rever <a href="#" onClick={ () => this.setState({ showModalTerms: true }) }> termos </a> e 
                                    <a href="#" onClick={ () => this.setState({ showModalPrivacidade: true }) }> condições</a>.
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row m-b-25">
                        <div className="col-12 d-flex justify-content-center">
                            <div className="assiantura-recaptcha">
                                <ReCAPTCHA
                                    sitekey="6Ldz0M4aAAAAAC2WWE44sG2XOJnyCsDp_V3KeELL"
                                    onChange={ this.validateFieldset2 }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="recaptcha-space-fake"></div>

                    <div className="row">
                        <div className="col-12 d-flex justify-content-center text-pink">
                            <a onClick={ this.returnToPreviousStep }>Voltar à etapa anterior</a>
                        </div>
                    </div>
                    <div className="row m-t-75">
                        <div className="col-12 text-center">
                            <If test={!this.props.assinaturaFormLanding3Reducer.submitLoading}>  
                                <button
                                    className={`the-button bg-pink btn-md text-white${!this.props.assinaturaFormLanding3Reducer.enableButtonConcluir ? " btn-disabled" : ""}`}
                                    onClick={handleSubmit}
                                    type="submit"
                                    disabled={ !this.props.assinaturaFormLanding3Reducer.enableButtonConcluir }
                                >
                                    ACESSAR AGORA!
                                </button>
                            </If>

                            <If test={this.props.assinaturaFormLanding3Reducer.submitLoading}>
                                <button
                                    className={`the-button bg-pink btn-md text-white btn-disabled`}
                                    onClick={handleSubmit}
                                    type="submit"
                                    disabled={ true }
                                >
                                    Salvando...
                                </button>
                            </If>
                        </div>
                    </div>
                    <If test={this.props.assinaturaFormLanding3Reducer.formError}>
                        <div className="row m-t-20">
                            <div className="col-12">
                                <div className="form-alert">
                                    <p>{this.props.assinaturaFormLanding3Reducer.formErrorMessage}</p>
                                </div>
                            </div>
                        </div>
                    </If>

                    <If test={ this.state.showModalTerms }>
                    <TheModal>
                        <button className="the-modal-close" onClick={() => this.setState({ showModalTerms: false }) }>X</button>

                        <div className="row m-b-30">
                            <div className="col-12">
                                <h4 className="f-montserrat f-24"><span className="f-w-700 text-pink">Termos e condições </span>de uso</h4>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="the-footer-termos-condicoes">
                                    <h5>1. SERVIÇO: Clube de Revistas</h5>
                                    <p>Neste documento, se estabelecem os Termos e Condições de Uso (daqui em diante denominados “T&C”) que regulam o uso do serviço e do app para dispositivos iOS ou Android ("DISPOSITIVOS") denominado “Clube de Revistas” (“SERVIÇO”), oferecido à operadora de telefonia móvel pela ZED BRASIL COMUNICAÇÃO LTDA., devidamente inscrita no CNPJ/MF sob o nº 03.953.519/0001-82, com sede na Calçada Vega, 23, 1º piso, Centro de Apoio 2, Alphaville – Santana do Parnaíba, SP, CEP 06541-040 (“FORNECEDOR”), para acesso dos clientes do Clube de Revistas e de outras pessoas ("USUÁRIOS") ao"SERVIÇO" por meio de conexão com a Internet em seus DISPOSITIVOS.</p>

                                    <h5>1. SERVIÇO: Clube de Revistas</h5>
                                    <p>1.1. O app "Clube de Revistas” consiste em um SERVIÇO de entretenimento que permite aos USUÁRIOS acessarem, através de DISPOSITIVOS iOS ou Android, edições de revistas do mercado que se encontrem disponíveis no catálogo e nas condições estabelecidas neste T&C.</p>
                                    <p>1.2. Os USUÁRIOS podem baixar na Apple App Store ou Google Play o app do SERVIÇO para seus DISPOSITIVOS e ter acesso ao catálogo grátis do SERVIÇO, sem necessidade de registro prévio, bastando para isso selecionar ENTRAR SEM LOGIN no topo da tela de login (identificação) do app.</p>
                                    <p>1.2.1 Opcionalmente, os usuários poderão acessar o conteúdo premium do “Clube de Revistas” diretamente pelo site www.clubederevistas.com com o mesmo formato de registro anteriormente descrito no item 1.3.</p>
                                    <p>1.3. Os USUÁRIOS com registro prévio no SERVIÇO podem ter acesso ao catálogo premium do SERVIÇO, sendo necessário que informem seus dados de registro selecionando os campos USUÁRIO e SENHA ou CPF da tela de login do app.</p>
                                    <p>1.3.2. O FORNECEDOR poderá conceder, excepcionalmente, sem qualquer cobrança adicional e por tempo limitado, acesso premium ao conteúdo do SERVIÇO a registros criados no SERVIÇO com números de telefonia móvel ou CPFs elegíveis.</p>
                                    <p>1.3.3. A elegibilidade de um número de telefone móvel ou CPF é definida pela operadora de telefonia do referido número ou CPF. A elegibilidade do número ou CPF deverá ser confirmada pelo usuário proprietário do número ou CPF junto a sua operadora de telefonia móvel. O usuário que informar um número ou CPF não-elegível na criação do registro não terá direito ao referido acesso ao conteúdo integral. </p>
                                    <p>1.3.4. Se o número ou CPF elegível vier a perder a elegibilidade após a criação da conta no SERVIÇO, o cliente proprietário do referido número ou CPF perderá o acesso integral ao conteúdo, e terá acesso apenas ao conteúdo gratuito, até que realize uma contratação do serviço de outra forma.</p>
                                    <p>1.4. Os USUÁRIOS poderão ler em modo online (com conexão ativa à Internet) as edições das revistas que desejem em seus DISPOSITIVOS, sem necessidade de descarregar as edições para o app.</p>
                                    <p>1.5. Opcionalmente, os USUÁRIOS podem descarregar para o app as edições das revistas desejadas, possibilitando a leitura posterior dessas edições em modo offline (sem conexão ativa com a Internet). Neste caso, as edições descarregadas ficam disponíveis na seção “Minhas Revistas”.</p>
                                    <p>1.6. É pré-requisito para a utilização do SERVIÇO que o USUÁRIO possua um DISPOSITIVO corretamente configurado.</p>
                                    <p>1.7. O uso do SERVIÇO está sujeito à aceitação e cumprimento do presente T&C. Também serão aplicáveis e válidas todas as condições específicas, avisos ou instruções de funcionamento que sejam divulgadas no website do Clube de Revistas ou nos meios relativos ao SERVIÇO, incluindo o app do SERVIÇO, com relação ao SERVIÇO em questão e às revistas disponíveis. Os USUÁRIOS poderão se comunicar com o FORNECEDOR através do email contato@clubederevistas.com para fazer consultas, reclamações ou sugestões.</p>
                                    <p>1.8. As revistas estarão disponíveis para leitura no momento em que o USUÁRIO solicitar a leitura em modo online da edição ou que efetue a descarga total em modo online para posterior leitura em modo offline. As revistas poderão ser lidas a partir de qualquer dos DISPOSITIVOS.</p>
                                    <p>1.9. Todos os USUÁRIOS que baixarem o app para seus DISPOSITIVOS declaram estar cientes e concordam com o conteúdo deste T&C.</p>

                                    <h5>2. Condições de Uso e Propriedade Intelectual</h5>
                                    <p>2.1. Os USUÁRIOS concordam em fazer uso lícito do SERVIÇO e das revistas que acessem a partir do app, em conformidade com a lei vigente aplicável e este T&C. É responsabilidade dos USUÁRIOS respeitar as normas mencionadas, em especial os direitos de propriedade intelectual e industrial, e abster-se de utilizar o SERVIÇO e/ou as revistas acessadas através do SERVIÇO para fins ilícitos ou que atentem contra os direitos de terceiros ou do FORNECEDOR. OS USUÁRIOS serão exclusivamente responsáveis pelos danos e prejuízos de qualquer natureza causados por uso incorreto, indevido, ilegítimo ou ilícito do SERVIÇO e das revistas. O USUÁRIO se compromete a utilizar o SERVIÇO de forma correta e consciente. Também se compromete a respeitar a lei, a moral, os bons costumes e a ordem pública ao usar o aplicativo do SERVIÇO. O USUÁRIO se compromete ainda a utilizar o SERVIÇO com fins lícitos e de acordo com as normas vigentes, sendo proibido o uso em atividades que afetem direta ou indiretamente direitos individuais e/ou coletivos de terceiros.</p>
                                    <p>2.2. O USUÁRIO se compromete a abster-se de: i) reproduzir, copiar, distribuir, permitir o acesso público através de qualquer forma de comunicação pública, transformar ou modificar os conteúdos do SERVIÇO e o próprio SERVIÇO, a menos que tenha autorização expressa do titular dos direitos ou que tenha autorização legal, ii) suprimir, alterar ou manipular o “copyright” e o “direito autoral” e demais dados dos direitos autorais dos titulares incorporados ao conteúdo do SERVIÇO, assim como os recursos técnicos de proteção, as impressões digitais ou qualquer mecanismo de informação que possa conter os conteúdos do SERVIÇO.</p>
                                    <p>2.3. O Clube de Revistas se reserva o direito de tomar as medidas que considere oportunas em caso de indícios de uso fraudulento do SERVIÇO, incluindo o uso de medidas legais, sem prejuízo das demais ações que possam ser tomadas pelas demais operadoras móveis.</p>
                                    <p>2.4. O Clube de Revistas e a FORNECEDORA são as únicas titulares das revistas e/ou receberam dos respectivos titulares das revistas uma licença de uso e publicação. As revistas estão protegidas por direitos autorais de acordo com as normas vigentes, e elas só podem ser utilizadas pelos USUÁRIOS da forma estabelecida e permitida por este T&C e pela legislação aplicável.</p>
                                    <p>2.5. Exceto se expressamente autorizado, todas as revistas descarregadas pelos USUÁRIOS no SERVIÇO não poderão ser transferidas, copiadas nem transmitidas a nenhum outro dispositivo. O USUÁRIO não poderá dar às revistas destino diferente do previsto. As revistas não poderão ser copiadas nem reproduzidas de nenhum modo, seja total ou parcialmente, para qualquer finalidade, sem autorização expressa e por escrito do FORNECEDOR.</p>
                                    <p>2.6. O Clube de Revistas não concede nenhuma licença ou autorização de uso de nenhum tipo sobre seus direitos de propriedade industrial e intelectual ou sobre qualquer propriedade ou direitos relacionados com o SERVIÇO ou que se encontrem vinculados a ele (isto inclui, mas não se restringe a: marcas, logotipos, desenhos, arte, títulos, símbolos e conteúdo). Toda obra intelectual, marca, logotipo, desenho, arte, títulos, símbolos, know-how, ou outras propriedades intelectuais em relação à imagem, nome, menção, voz, fonogramas ou outras pertencem à operadora e é proibida sua reprodução, distribuição, comercialização e qualquer outro uso não expressamente autorizado pelo Clube de Revistas.</p>

                                    <h5>3. Responsabilidade</h5>
                                    <p>3.1. O Clube de Revistas não poderá ser responsabilizada por qualquer dano ou prejuízo causado ou que possa ser causado a USUÁRIOS ou a terceiros, pessoal ou materialmente, pela utilização do SERVIÇO. Tampouco será responsável em caso de insatisfação em relação às revistas disponíveis no SERVIÇO.</p>
                                    <p>3.2. O Clube de Revistas não se responsabiliza por envios ou pedidos que não contenham os dados solicitados ou que contenham dados errôneos, nem por pedidos ou envios que não sejam aceitos pela plataforma tecnológica do Clube de Revistas, nem por atrasos que possam sofrer a descarga das revistas, a publicação de novas edições das revistas, as mensagens textos ou qualquer outro item relacionado ao SERVIÇO, por qualquer causa que não seja de responsabilidade do Clube de Revistas, incluindo porém sem se limitar a: falhas de conectividade da rede móvel, erros no envio dos pedidos, erro no fornecimento de dados, excesso ou saturação do tráfego de rede e/ou qualquer característica dos aparelhos que impeça a transmissão das solicitações, envios, recebimentos ou descargas mencionados.</p>
                                    <p>3.3. O Clube de Revistas se reserva o direito de efetuar sem aviso prévio todo e qualquer tipo de modificação na mecânica de uso do SERVIÇO, apenas comunicando modificações relevantes nos meios relativos ao SERVIÇO e/ou outros meios.</p>
                                    <p>3.4. O USUÁRIO aceita que o uso do SERVIÇO é de sua própria, exclusiva, única e inteira responsabilidade.</p>
                                    <p>3.5. O Clube de Revistas não tem obrigação de controlar e não controla a utilização que os USUÁRIOS fazem do SERVIÇO. Em particular, o Clube de Revistas não garante que os USUÁRIOS utilizem o SERVIÇO em conformidade com este T&C nem que o façam de forma prudente e consciente. O Clube de Revistas não se responsabiliza por nenhuma ação ilícita que se possa derivar do uso do SERVIÇO.</p>
                                    <p>3.6. O Clube de Revistas não garante a privacidade e a segurança dos USUÁRIOS na utilização do SERVIÇO e, em particular, não garante que terceiros não possam interceptar, eliminar, alterar e/ou manipular qualquer material e/ou informação publicada. O Clube de Revistas se exime de qualquer responsabilidade pelos danos e prejuízos de qualquer natureza que se possam originar a partir do conhecimento que terceiros possam ter dos conteúdos e/ou dos SERVIÇOS, dados, páginas e/ou informação utilizada e/ou publicada através do SERVIÇO.</p>
                                    <p>3.7. O Clube de Revistas não será responsável por qualquer dano ou prejuízo, direto ou indireto, de qualquer natureza, incluindo sem se limitar a: danos produzidos por perda ou deterioração da informação, pelo atraso, interrupção e defeitos que possam existir nas transmissões durante o uso do SERVIÇO, pela utilização do SERVIÇO, vírus ou falhas de linha, por falta de disponibilidade e/ou continuidade do SERVIÇO e a utilização que os USUÁRIOS fazem do mesmo.</p>
                                    <p>3.8. Dados pessoais: O Clube de Revistas poderá captar e utilizar, mediante consentimento expresso dos USUÁRIOS do SERVIÇO, seus dados pessoais através de integração com redes sociais ou questionários, para qualquer dos seguintes fins: análises estatísticas, divulgação de outros produtos e/ou SERVIÇOS do Clube de Revistas ou de seus parceiros, promoção de melhorias no SERVIÇO, pesquisas de opinião relativas ao SERVIÇO e/ou produtos e/ou SERVIÇOS do Clube de Revistas ou de seus parceiros. O Clube de Revistas em hipótese alguma compartilhará os dados pessoais mencionados com outras empresas que não sejam a próprio Clube de Revistas, o FORNECEDOR ou parceiros do Clube de Revistas que obedeçam estritamente às regras de sigilo de dados da operadora.</p>

                                    <h5>4. Da aplicação</h5>
                                    <p>4.1. O Clube de Revistas não garante a disponibilidade, continuidade nem, de forma geral, o funcionamento normal do SERVIÇO em caso de incidências ou interrupções no funcionamento normal das redes envolvidas no SERVIÇO. O Clube de Revistas poderá atualizar o app do SERVIÇO a qualquer momento e sem prévio aviso aos USUÁRIOS.</p>
                                    <p>4.2. O USUÁRIO poderá desinstalar o app do SERVIÇO instalado em qualquer dos seus DISPOSITIVOS a qualquer momento, acessando as configurações dos mesmos, considerando as funcionalidades do aparelho que possui.</p>

                                    <h5>5. Jurisdição</h5>
                                    <p>5.1. Para dirimir qualquer questão oriunda do SERVIÇO que surja entre os USUÁRIOS e o Clube de Revistas, ou entre os USUÁRIOS e o FORNECEDOR, a legislação aplicável é a brasileira, renunciando expressamente os USUÁRIOS a aplicação de qualquer outra lei à qual possam ter direito.</p>
                                    <p>5.2. Fica eleito o foro central da Comarca Central da Capital de São Paulo, para dirimir quaisquer divergências que possam surgir em decorrência do presente T&C e que não possam ser solucionadas de forma amigável.</p>
                                </div>
                            </div>
                        </div>
                    </TheModal>
                </If>
                <If test={ this.state.showModalPrivacidade }>
                    <TheModal>
                        <button className="the-modal-close" onClick={() => this.setState({ showModalPrivacidade: false }) }>X</button>

                        <div className="row m-b-30">
                            <div className="col-12">
                                <h4 className="f-montserrat f-24">Política de <span className="f-w-700 text-pink">privacidade</span></h4>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="the-footer-politica-privacidade">
                                    <h5>Quais são as políticas de privacidade adotadas pelo Clube de Revistas?</h5>
                                    <p>A sua privacidade é muito importante para o Clube de Revistas. Dessa forma, desenvolvemos uma Política de Privacidade que dispõe sobre a maneira como nós obtemos, usamos, divulgamos, transferimos e armazenamos suas informações. Por favor, verifique as nossas práticas de privacidade e no informe caso tenha quaisquer questionamentos.</p>

                                    <h5>1. Obtenção e Uso de Informações Pessoais</h5>
                                    <p>1.1 Informações pessoais são dados que podem ser usados para identificar individualmente ou entrar em contato com uma pessoa em específico.</p>
                                    <p>1.2 Você poderá ser solicitado a fornecer suas informações pessoais a qualquer momento, enquanto estiver em contato com o Clube de Revistas.</p>

                                    <h5>2. Quais informações pessoais obtemos</h5>
                                    <p>2.1 Quando você se inscrever, através do nosso Serviço, para ser um usuário registrado do Clube de Revistas, poderemos obter uma série de informações, entre elas, seu nome, gênero, endereço eletrônico, endereço de correspondência, número de telefone, preferências de contato e informações de cartão de crédito.</p>
                                    <p>2.2 Se você decidir se inscrever, através do nosso Serviço, se logando em um serviço de rede social, você estará utilizando as informações pessoais que já forneceu ao serviço de rede social, para ser um usuário registrado Clube de Revistas. As informações que poderemos obter, nesse caso, dependem das suas configurações de privacidade junto ao serviço de rede social. Por exemplo, ao utilizar o Facebook para se inscrever, você estará permitindo que o Clube de Revistas acesse as informações pessoais em sua conta do Facebook.</p>

                                    <h5>3. Como usamos suas informações pessoais</h5>
                                    <p>3.1 As informações pessoais que obtemos (em alguns casos, em conjunto com informações não-pessoais) nos permitem fornecer o Serviço a você. Por exemplo:</p>
                                    <p>3.1.1 Registro de Usuário. Nós criamos sua Conta de Usuário para seu uso do Serviço, baseado nas informações pessoais por você fornecidas.</p>
                                    <p>3.1.2 Comunicação. Nós usamos suas informações pessoais para enviar newsletters, bem como outros materiais promocionais e de marketing. Se você não quiser mais fazer parte da nossa lista de mala direta, poderá optar por isso a qualquer momento alterando as suas preferências. Periodicamente poderemos usar suas informações pessoais para enviar avisos importantes, como comunicados sobre compras, alterações em prazos, condições e políticas. Como estas informações são importantes para a sua interação com o Clube de Revistas, você não poderá optar por não receber esse tipo de comunicação.</p>
                                    <p>3.1.3 Promoções. Se você participar de um concurso, competição ou algum tipo de promoção, poderemos usar as informações que você fornecer para administrar esses programas.</p>
                                    <p>3.1.4 Outros. Nós também usamos informações pessoais para nos ajudar a desenvolver, oferecer e melhorar os nossos produtos, serviços, conteúdo e publicidade. Também poderemos usar informações pessoais para fins internos, tais como auditoria, análise de dados e pesquisas para aprimorar os produtos, serviços e comunicações com os clientes.</p>
                                    queryQuestions
                                    <h5>4. Obtenção e Uso de Informações Não-Pessoais</h5>
                                    <p>4.1 Nós também obteremos informações não-pessoais, ou seja, dados que não permitem a associação direta com qualquer pessoa especificamente. Nós poderemos obter, usar, transferir e divulgar informações não-pessoais para qualquer finalidade. São exemplos de dados não-pessoais, não se limitando a esses, idade, preferências individuais, idioma, CEP e código de área.</p>
                                    <p>4.2 Nós também poderemos obter informações sobre as atividades dos usuários no nosso site. Essas informações são agregadas e usadas para nos ajudar a fornecer informações abalizadas para os nossos clientes e entender quais partes de nosso site, produtos e serviços atraem mais interesse. Dados agregados são considerados informações não-pessoais para fins desta Política de Privacidade.</p>
                                    <p>4.3 Se combinarmos informações não-pessoais com informações pessoais, a informação combinada será tratada como informação pessoal enquanto essa informação permanecer combinada.</p>

                                    <h5>5. Cookies e Outras Tecnologias</h5>
                                    <p>5.1 O site do Clube de Revistas, os serviços online, os aplicativos interativos, as mensagens de e-mail e o material publicitário poderão usar "cookies" e outras tecnologias tais como pixel tags e web beacons. Essas tecnologias nos ajudam a entender melhor o comportamento do usuário, nos dizem quais partes de nosso site as pessoas visitaram e facilitam e medem a eficácia da publicidade e das pesquisas na web. Nós tratamos as informações obtidas por meio de cookies e outras tecnologias como informações não-pessoais. Entretanto, na medida em que o endereço de IP (Internet Protocol) ou identificadores semelhantes sejam considerados informações pessoais pela lei local, nós também trataremos esses identificadores como informação pessoal. Da mesma forma, na medida em que a informação não-pessoal seja combinada com informação pessoal, nós tratamos a informação combinada como informação pessoal para os fins desta Política de Privacidade.</p>
                                    <p>5.2 O Clube de Revistas também usa cookies e outras tecnologias para relembrar informações pessoais quando você usa o site, serviços online e aplicativos. A nossa meta nesses casos é fazer com que a sua experiência com o Clube de Revistas seja mais conveniente e personalizada. Por exemplo, saber o seu primeiro nome nos permite dar boas-vindas a você na próxima vez em que você acessar. Conhecer seu país e idioma nos permite viabilizar a você uma experiência de compras personalizada e mais útil. Saber que alguém usando o seu computador ou dispositivo adquiriu determinado produto ou usou um determinado serviço permite fazer com que sua publicidade e comunicações de e-mail sejam mais relevantes para os seus interesses.</p>
                                    <p>5.3 Se você deseja bloquear os cookies, consulte as orientações do seu navegador.</p>
                                    <p>5.4 Como ocorre na maioria dos sites, nós obtemos algumas informações automaticamente e armazenamos em arquivos de log. Entre essas informações estão endereços IP, tipo de navegador e idioma, provedor de serviços de Internet (ISP), páginas de consulta e saída, sistema operacional, informações sobre data e horário e dados sobre a sequência de cliques.</p>
                                    <p>5.5 Nós usamos essas informações para entender e analisar tendências, administrar o site, aprender sobre o comportamento do usuário no site e obter informações demográficas sobre a nossa base de usuários de maneira geral. O Clube de Revistas poderá usar estas informações em nossos serviços de marketing e publicidade.</p>
                                    <p>5.6 Em algumas de nossas mensagens de e-mail, nós usamos uma "URL click-through" vinculada ao conteúdo do site do Clube de Revistas. Quando os clientes clicam em uma dessas URLs, os usuários são enviados para um servidor web diferente antes de chegarem à página de destino no nosso site. Nós monitoramos esses dados de click-through para entender o interesse em determinados tópicos e avaliar a eficácia das comunicações com os nossos clientes. Se você preferir não ser monitorado dessa maneira, não clique em texto nem nos links de imagens nas mensagens de e-mail.</p>
                                    <p>5.7 Os pixel tags nos permitem enviar mensagens de e-mail em formatos que os clientes possam ler e nos dizer se o e-mail foi aberto ou não. Nós podemos usar essas informações para reduzir ou eliminar as mensagens enviadas aos clientes.</p>
                                    <p>5.8 A qualquer momento, caso não queira mais receber futuros contatos via e-mail formato newsletter, basta acessar o link de descadastramento no e-mail.</p>

                                    <h5>6. Política referente a dados coletados pelo Google</h5>
                                    <p>6.1 O Clube de Revistas utiliza a Publicidade de Visualização do Google, e implementa a funcionalidade de Remarketing para anunciar online. Desta forma, fornecedores terceiros, incluindo o Google, mostram os nossos anúncios em Websites na Internet.</p>
                                    <p>6.2 O Clube de Revistas utiliza, incluindo o Google, cookies originais (como o cookie do Google Analytics) e cookies de terceiros (como o cookie DoubleClick) em conjunto para informar, otimizar e publicar anúncios com base nas visitas passadas de alguém ao seu Website.</p>
                                    <p>6.3 Os visitantes podem excluir a Publicidade de Visualização do Google Analytics e personalizar anúncios na Rede de Display da Google com o Gestor das Preferências de Anúncios. Assim como podem também fazer o download da extensão do navegador para desativar o Google Analytics.</p>

                                    <h5>7. Política de rastreamento de anúncios do Facebook</h5>
                                    <p>7.1 Temos o compromisso de proteger os usuários e seus dados. Para limitar os tipos de dados que podem ser obtidos sobre os usuários por meio das tecnologias de acompanhamento de anúncios, e permitir ao mesmo tempo que os anunciantes obtenham as informações de campanha de que precisam, adotamos as inclusões abaixo em nossas políticas que regem os anúncios no Facebook.</p>
                                    <p>7.2 Os dados obtidos em consequência da exibição de uma propaganda a um usuário ("Dados de acompanhamento de impressão") são permitidos somente em alguns produtos de propaganda, conforme estabelecido pelo Facebook. Os dados de rastreamento de impressão não podem ser obtidos em qualquer produto de propaganda leiloado.</p>
                                    <p>7.3 Os dados obtidos em consequência do clique em uma propaganda ("dados de rastreamento de clique") por um usuário são permitidos na maioria dos produtos de propaganda. Os dados de rastreamento de clique podem ser obtidos por meio do uso de uma única URL de rastreamento fora do Facebook. Os dados de rastreamento de clique não incluem dados relacionados às ações paralelas do usuário, como clicar no botão Curtir, responder a um evento ou a uma enquete.</p>

                                    <h5>8. Divulgação para terceiros</h5>
                                    <p>8.1 Usuários do Clube de Revistas. Quando você se inscreve no Serviço e submete informações pessoais para criar um perfil ou coleção visual, outros usuários verão seu nome e foto. Se você optou por fornecer informações pessoais adicionais em seu perfil, usuários do Clube de Revistas verão tais informações adicionais, incluindo, mas não se limitando a, localização, site e informações biográficas. Fornecer informação adicional é opcional. Nós recomendamos que você proteja e não divulgue qualquer informação que você considere sensível ou confidencial.</p>
                                    <p>8.2 Prestadores de Serviço. Nós podemos utilizar empresas terceirizadas e pessoas físicas para facilitar nosso Serviço, prover o Serviço em nosso nome, executar atividades relacionadas ao Serviço, incluindo, mas não se limitando a, manutenção dos serviços, gerenciamento de banco de dados, análise de dados e melhoria de funcionalidades, ou nos auxiliar em analisar como o nosso Serviço é usado. Essas empresas ou pessoas físicas têm acesso à sua informação pessoal apenas para executar tarefas para o uso do Clube de Revistas e são obrigados a não usar ou divulgar tais informações para nenhuma outra finalidade.</p>
                                    <p>8.3 Serviços de Redes Sociais. Nós podemos divulgar informações de sua conta no Clube de Revistas (incluindo informações pessoais) para o serviço de rede social ligado à sua conta Clube de Revistas. Nós permitimos que os Usuários conectem suas contas de serviços de redes sociais aos nossos Serviços. Os usuários poderão então postar atividades recentes em nossos serviços de volta para suas contas de redes sociais. Se você não quiser mais postar suas atividades em sua conta de rede social, poderá fazê-lo a qualquer momento, alterando as suas preferências.</p>
                                    <p>8.4 Outros. O Clube de Revistas poderá ser obrigado por lei, processo, litígio e/ou determinações de autoridades públicas ou governamentais dentro ou fora do país em que você reside, a divulgar suas informações pessoais. Nós também poderemos divulgar informações sobre você se essa divulgação tem por finalidade a segurança nacional, exigência legal ou outros assuntos de importância pública ou quando a divulgação for necessária ou apropriada. Nós também poderemos divulgar informações sobre você se nós entendermos que a divulgação é razoavelmente necessária para fazer valer nossos termos e condições ou proteger nossas operações ou usuários. Além disso, no caso de uma reestruturação, fusão ou venda, poderemos transferir toda e qualquer informação pessoal que obtivermos ao terceiro de que se trate.</p>

                                    <h5>9. Proteção de Informações Pessoais</h5>
                                    <p>9.1 O Clube de Revistas toma precauções, entre elas medidas administrativas, técnicas e físicas, para proteger as suas informações pessoais contra perda, roubo, uso indevido, bem como contra acesso não autorizado, divulgação, alteração e destruição.</p>

                                    <h5>10. Integridade e Retenção de Informações Pessoais</h5>
                                    <p>10.1 O Clube de Revistas o ajuda a manter as suas informações pessoais precisas, completas e atualizadas. Nós reteremos suas informações pessoais pelo período necessário para atender as finalidades apresentadas nesta Política de Privacidade, a menos que um período maior de retenção seja necessário ou permitido por lei.</p>

                                    <h5>11. Dúvidas sobre Privacidade</h5>
                                    <p>11.1 Se você tiver algum questionamento ou dúvida com relação à Política de Privacidade do Clube de Revistas ou qualquer prática descrita aqui, entre em contato conosco através do e-mail contato@clubederevistas.com.</p>
                                    <p>11.2 O Clube de Revistas poderá atualizar a sua Política de Privacidade periodicamente. Se fizermos alguma alteração na política em termos materiais, colocaremos um aviso no nosso site, juntamente com a Política de Privacidade atualizada.</p>
                                </div>
                            </div>
                        </div>
                    </TheModal>
                </If>



                </fieldset>
            </form>
        )
    }
}

AssinaturaFormLanding3 = reduxForm({
    form: "assinaturaLanding3Form",
    destroyOnUnmount: true,
    onSubmitSuccess: (result, dispatch) => { dispatch(reset("assinaturaLanding3Form")) },
    onSubmitFail: () => { return false }
})(AssinaturaFormLanding3);
const mapStateToProps = state => ({ 
    assinaturaFormLanding1Reducer: state.assinaturaFormLanding1Reducer,
    assinaturaFormLanding3Reducer: state.assinaturaFormLanding3Reducer,
    theFooterreducer: state.theFooterreducer,
});
const mapDispatchToProps = dispatch => bindActionCreators({
    setButtonCadastrarEnable,
    setButtonConcluirEnable,
    change,
    setRecaptchaToken,
    setSubmitLoading,
    setTermosCondicoesModalOpened,
    setPoliticaPrivacidadeModalOpened,
    // setTermosCondicoesModalOpenedLanding1,
    // setPoliticaPrivacidadeModalOpenedLanding1,
    setTermosCondicoesModalOpenedLanding3,
    setPoliticaPrivacidadeModalOpenedLanding3,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AssinaturaFormLanding3);