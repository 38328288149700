export const setButtonCadastrarEnable = state => {
    return dispatch => {
        dispatch({ type: "SET_ENABLE_BUTTON_CADASTRAR", payload: state });
    }
}

export const setButtonConcluirEnable = state => {
    return dispatch => {
        dispatch({ type: "SET_ENABLE_BUTTON_CONCLUIR", payload: state });
    }
}

export const setSubmitLoading = state => {
    return dispatch => {
        dispatch({ type: "SET_FORM_LANDING1_SUBMIT_LOADING", payload: state });
    }
}

export const setFormError = state => {
    return dispatch => {
        dispatch({ type: "SET_FORM_ERROR", payload: state });
    }
}

export const setFormErrorMessage = state => {
    return dispatch => {
        dispatch({ type: "SET_FORM_ERROR_MESSAGE", payload: state });
    }
}

export const setRecaptchaToken = token => {
    return dispatch => {
        dispatch({ type: "SET_FORM_LANDING1_RECAPTCHA_TOKEN", payload: token });
    }
}

export const setTermosCondicoesModalOpened = state => {
    return dispatch => {
        dispatch({ type: "SET_ASSINATURA_LANDING_1_TERMOS_CONDICOES_MODAL_OPENED", payload: state });
    }
}

export const setPoliticaPrivacidadeModalOpened = state => {
    return dispatch => {
        dispatch({ type: "SET_ASSINATURA_LANDING_1_POLITICA_PRIVACIDADE_MODAL_OPENED", payload: state });
    }
}