const INITIAL_STATE = { currentTitulo: {}, titulos: [] };

export const reducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_HOME_TITULOS_DESTAQUE":
            return { ...state, titulos: action.payload }

        case "SET_HOME_TITULOS_DESTAQUE_CURRENT_TITULO":
            return { ...state, currentTitulo: action.payload }
        default:
            return state;
    }
}