const INITIAL_STATE = {
    videoThumbnail: "",
    videoId: "",
    videoModalOpened: false
};

export const reducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_HOME_FACA_PARTE_VIDEO_THUMBNAIL":
            return { ...state, videoThumbnail: action.payload };
        case "SET_HOME_FACA_PARTE_VIDEO_ID":
            return { ...state, videoId: action.payload };
        case "SET_HOME_FACA_PARTE_VIDEO_MODAL_OPENED":
            return { ...state, videoModalOpened: action.payload };
        default:
            return state;
    }
}