export const setModal1Opened = state => {
    return dispatch => {
        dispatch({ type: "SET_MODAL1_OPENED", payload: state });
    };
}

export const getVideoUrl = () => {
    return async dispatch => {
        fetch("data.json", { headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }})
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(data => {
            if (data["landing-page-1"] && 
                data["landing-page-1"]["vantagens-do-clube"] && 
                data["landing-page-1"]["vantagens-do-clube"]["video"]) 
            {
                dispatch({ type: "SET_VIDEO", payload: data["landing-page-1"]["vantagens-do-clube"]["video"] })
            }
        });
    }
}

export const getPrincipaisTitulosImages = () => {
    return async dispatch => {
        fetch("data.json", { headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }})
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(async data => {
            if (data["landing-page-1"] && 
                data["landing-page-1"]["vantagens-do-clube"] && 
                data["landing-page-1"]["vantagens-do-clube"]["principais-titulos"])
            {
                const imagesList = [];

                await data["landing-page-1"]["vantagens-do-clube"]["principais-titulos"].forEach(async (imageName) => {
                    const imageRequired = await require(`../../assets/images/landing-page-1/${imageName}`);
                    imagesList.push(imageRequired.default);
                });

                dispatch({ type: "SET_PRINCIPAIS_TITULOS_IMAGES", payload: imagesList });
            }
        })
    }
}