import React, { Component } from "react";
import $ from "jquery";

import "./ComoFuncionaLanding1.scss";

import AssetIconBook from "../../assets/images/icon-book.png";
import AssetAppleStorePink from "../../assets/images/apple-store-pink.png";
import AssetGooglePlayPink from "../../assets/images/google-play-pink.png";
import assetArrowWhiteDown from "../../assets/images/arrow-white-down.png";

import SlickCarousel from "../SlickCarousel/SlickCarousel";
import Row from "../Row/Row";
import Grid from "../Grid/Grid";
export class ComoFuncionaLanding1 extends Component {
    scrollTo(element) {
        const scrollTop = $(element).offset().top;
        window.scrollTo(0, scrollTop);
    }

    render () {
        return (
            <section id="como-funciona-l1" className="como-funciona-l1">
                <div className="d-none h-100 d-xl-block">
                    <div className="d-table w-100 h-100 d-none">
                        <div className="como-funciona-l1-box d-table-cell w-100 h-100">
                            <div className="container">
                                <div className="como-funciona-container">
                                    <div className="como-funciona-content">
                                        <div className="row m-b-40">
                                            <div className="col-12 text-center">
                                                <img src={AssetIconBook} />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <h2 className="f-w-700 f-montserrat">Como <span className="text-pink">funciona</span>?</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="como-funciona-steps">
                                        <div className="como-funciona-step step-1">
                                            <div className="como-funciona-step-ball ball-1"></div>

                                            <h4>Assine</h4>
                                            <p>Faça seu cadastro de forma simples e realize o pagamento com cartão de crédito. Sua assinatura é liberada logo após a aprovação do pagamento.</p>
                                        </div>
                                        
                                        <div className="como-funciona-step step-2">
                                            <div className="como-funciona-step-ball ball-2"></div>

                                            <h4>Acesse</h4>
                                            <p>Entre em <a href="https://clubederevistas.com/">nosso site</a> ou baixe nosso app na loja do seu celular ou tablet.</p>

                                            <div className="d-flex como-funciona-stores-links">
                                                <a href="https://apps.apple.com/br/app/clube-de-revistas/id1117256234?l=en" target="_blank">
                                                    <img src={AssetAppleStorePink} />
                                                </a>

                                                <a href="https://play.google.com/store/apps/details?id=br.zed.clubederevistas" target="_blank">
                                                    <img src={AssetGooglePlayPink} />
                                                </a>
                                            </div>
                                        </div>
                                        
                                        <div className="como-funciona-step step-3">
                                            <div className="como-funciona-step-ball ball-3"></div>

                                            <h4>Leia</h4>
                                            <p>Pronto! Agora, é só curtir sua leitura e se manter sempre atualizado com todos os títulos disponíveis!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="como-funciona-l1-arrow-down">
                                <a href="#ultimas-edicoes">
                                    <img 
                                        src={assetArrowWhiteDown} 
                                        alt="Seta para baixo"
                                        onClick={ () => this.scrollTo("#lancamentos-l1") }
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-block d-xl-none">
                    <div className="w-100 h-100">
                        <div className="como-funciona-l1-box w-100 h-100">
                            <div className="container">
                                <div className="como-funciona-container">
                                    <div className="como-funciona-content">
                                        <div className="row m-b-20">
                                            <div className="col-12 text-center">
                                                <img src={AssetIconBook} className="como-funciona-book-icon" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <h2 className="f-w-700 f-20 f-montserrat">Como <span className="text-pink">funciona</span>?</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <Row>
                                        <Grid cols="12">
                                            <SlickCarousel
                                                dots={false}
                                                infinite={true}
                                                slidesToShow={1}
                                                slidesToScroll={1}
                                                autoplay={false}
                                                cssEase={"linear"}
                                                initialSlide={0}
                                            >
                                                <div className="item como-funciona-step step-1">
                                                    <div className="como-funciona-step-ball ball-1"></div>

                                                    <h4>Assine</h4>
                                                    <p>Cadastre seu e-mail e realize o pagamento via cartão de crédito.  Sua assinatura é liberada logo após a aprovação do pagamento.</p>
                                                </div>
                                                <div className="item como-funciona-step step-2">
                                                    <div className="como-funciona-step-ball ball-2"></div>

                                                    <h4>Acesse</h4>
                                                    <p>Entre em <a href="https://clubederevistas.com/">nosso site</a> ou baixe um de nossos aplicativos móveis. </p>

                                                    <div className="d-flex como-funciona-stores-links">
                                                        <a href="https://apps.apple.com/br/app/clube-de-revistas/id1117256234?l=en" target="_blank">
                                                            <img src={AssetAppleStorePink} />
                                                        </a>

                                                        <a href="https://play.google.com/store/apps/details?id=br.zed.clubederevistas" target="_blank">
                                                            <img src={AssetGooglePlayPink} />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="como-funciona-step step-3">
                                                    <div className="como-funciona-step-ball ball-3"></div>

                                                    <h4>Leia</h4>
                                                    <p>Você pode acessar todas as revistas, sem limites e a qualquer momento, dentro do período de sua assinatura.</p>
                                                </div>
                                            </SlickCarousel>
                                        </Grid>
                                    </Row>
                                </div>
                            </div>

                            <div className="como-funciona-l1-arrow-down">
                                <a href="#ultimas-edicoes">
                                    <img 
                                        src={assetArrowWhiteDown} 
                                        alt="Seta para baixo"
                                        onClick={ () => this.scrollTo("#lancamentos-l1") }
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ComoFuncionaLanding1;