export const getVariablesFromJson = () => {
    return dispatch => {
        fetch("variables.json", { 
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(async data => {
            dispatch({ type: "SET_VARIABLES", payload: data });
        })
    }
}