const buildNumber = (valueOnlyNumbers, month, year) => {
    let buildedNumber = "";

    if (valueOnlyNumbers.length < 2) buildedNumber = `${month}`;
    else if (valueOnlyNumbers.length === 2) buildedNumber = `${month}/`;
    else if (valueOnlyNumbers.length > 2) buildedNumber = `${month}/${year}`;

    return buildedNumber;
}

const normalizeCreditValidity = (value, prevValue) => {
    if (!value) return value;

    const valueOnlyNumbers = value.replace(/[^\d]/g, '');
    const prevValueOnlyNumbers = prevValue && prevValue.replace(/[^\d]/g, '');

    if (valueOnlyNumbers.length > 6 || value.length > 7) return prevValue;
    if (valueOnlyNumbers === prevValueOnlyNumbers) return value;

    const month = valueOnlyNumbers.slice(0, 2);
    const year = valueOnlyNumbers.slice(2, 6);

    return buildNumber(valueOnlyNumbers, month, year);
}

export default normalizeCreditValidity;