const INITIAL_STATE = { modal1: false, video: "", principaisTitulosImages: [] };

export const reducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_MODAL1_OPENED":
            return { ...state, modal1: action.payload };
        case "SET_VIDEO":
            return { ...state, video: action.payload };
        case "SET_PRINCIPAIS_TITULOS_IMAGES":
            return { ...state, principaisTitulosImages: action.payload };
        default:
            return state;
    }
}