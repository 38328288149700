import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import $ from "jquery";

import "../AssinaturaFormLanding1/AssinaturaFormLanding1.scss"
import "./AssinaturaLanding3.scss"

import TheModal from "../../components/TheModal/TheModal"
import If from "../If/If";
import { setAssinaturaConcluidaModalOpened } from "../../redux/actions/AssinaturaLanding3Actions";
import { getApiErrorMessage } from "../../common/zedApi";
import { 
    setSubmitLoading,
    setFormError,
    setFormErrorMessage,
    setButtonCadastrarEnable,
    setButtonConcluirEnable,
    setTermosCondicoesModalOpened,
    setPoliticaPrivacidadeModalOpened
} from "../../redux/actions/AssinaturaFormLanding3Actions";
import { getVariablesFromJson } from "../../redux/actions/VariablesFromJson";
import AssinaturaFormLanding3 from "../AssinaturaFormLanding3/AssinaturaFormLanding3";
import TheFooter from "../../components/TheFooter/TheFooter"

import AlertAssinaturaDone from "../../components/AlertAssinaturaDone/AlertAssinaturaDone"

import ReactGA from 'react-ga';

class AssinaturaLanding3 extends Component {
    constructor(props) {
        super(props);

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.submitAssinatura = this.submitAssinatura.bind(this);

        this.state = {
            VARIABLES: {},
            showModalSuccess: false
        }
    }

    componentWillMount() {
        const body = document.body
        body.classList.add('bg-lp-3')
    }

    showModal() {
        this.props.setAssinaturaConcluidaModalOpened(true);
    }

    hideModal() {
        this.props.setAssinaturaConcluidaModalOpened(false);
    }

    async getGatewayToken(values) {
        this.props.setSubmitLoading(true);
        this.props.setFormError(false);

        const data = {
            holder_name: values.creditCardHolder || "",
            card_expiration: values.creditCardValidity || "",
            card_number: values.creditCardNumber || "",
            card_cvv: values.creditCardCVC || "",
            payment_method_code: "credit_card",
            payment_company_code: values.creditCardFlag || ""
        }

        const config = {
            headers: {
                "Authorization": `Basic ${btoa("QmA-E55Kq3HO-B_ioGz4DdHC1UPl2q6aVF3MbVJ-fPA")}` 
            }
        }

        return axios.post("https://app.vindi.com.br/api/v1/public/payment_profiles", data, config)
            .then(response => {
                const success = response.status === 201;

                if (success) {
                    const token = response.data.payment_profile.gateway_token;

                    return token;
                } else {
                    this.props.setSubmitLoading(false);
                    this.props.setFormError(true);
                    this.props.setFormErrorMessage('Ocorreu um erro no processamento do seu pagamento. Se o erro persistir, entre em contato com a operadora do seu cartão.');
                    return false;
                }
            })
            .catch(e => {
                this.props.setFormError(true);
                this.props.setFormErrorMessage('Ocorreu um erro no processamento do seu pagamento. Se o erro persistir, entre em contato com a operadora do seu cartão.');
                this.props.setSubmitLoading(false);
                return false;
            });
    }

    async sendDataToZed(values, gatewayToken) {
        const urlParams = new URLSearchParams(window.location.search);

        const token_autentication = localStorage.getItem('token_autentication')
        const data = {
            "email": values.customerEmail,
            "registry_code": values.creditCardCPF,
            "holder_name": values.creditCardHolder,
            "plan_id": this.state.VARIABLES.plan_id,
            "clickid": urlParams.get('clickid'),
            "token_autentication": token_autentication,
            "gateway_token": gatewayToken
        }

        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        return axios.post("https://ads.clubederevistas.com/api/communication/index.php", data, config)
            .then(async response => {
                if (response.data.status && response.data.status === "0") {
                    const errorMessage = await getApiErrorMessage(response.data.info);

                    ReactGA.event({
                        category: 'CR-LP',
                        action: 'ConcluirAssinatura',
                        label: 'Usuário informou dados do cartão e concluiu'
                    });

                    this.props.setSubmitLoading(false);
                    this.props.setFormError(true);
                    this.props.setFormErrorMessage(errorMessage);
                    
                    return false;
                } else if (response.data.status && response.data.status === "1") {
                    this.setState({ showModalSuccess: true })
                    return true;
                } else {
                    return false;
                }
            })
            .catch(async e => {
                const errorMessage = await getApiErrorMessage(e.response.data.info);

                this.props.setSubmitLoading(false);
                this.props.setFormError(true);
                this.props.setFormErrorMessage(errorMessage);
                
                return false;
            });
    }

    async submitAssinatura(values) {
        if (!values.recaptchaToken) {
            values.recaptchaToken = this.props.assinaturaFormLanding3Reducer.recaptchaToken;
        }
        
        const gatewayToken = await this.getGatewayToken(values);
        
        if (gatewayToken) {
            const response = await this.sendDataToZed(values, gatewayToken);
            
            if (response == true) {     
                await this.props.setAssinaturaConcluidaModalOpened(true);
                await this.props.setSubmitLoading(false);

                $("#assinatura-form #email-info").fadeIn();
                $("#assinatura-form #credit-card-info").fadeOut();

                this.props.setButtonConcluirEnable(false);
                this.props.setButtonCadastrarEnable(false);

                return true;
            } else {
                this.props.setButtonConcluirEnable(false);
                this.props.setButtonCadastrarEnable(false);

                throw "Erro";
            }
        } else {
            this.props.setButtonConcluirEnable(false);
            this.props.setButtonCadastrarEnable(false);

            throw "Erro";
        }
    }

    async componentDidMount() {
        await this.props.getVariablesFromJson()

        fetch("variables.json", { 
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(data => {
            this.setState({
                VARIABLES: data
            })
        })
    }

    render() {
        return (
            <section id="assinatura-l1" className="assinatura-l1">
                <div className="d-table w-100 h-100">
                    <div className="assinatura-l1-box d-table-cell w-100 h-100--">
                        <div className="container assinatura-l1-box-wrap text-orange-white">
                            <div className="assinatura-l1-container">

                                <If test={ this.state.showModalSuccess }>
                                    <TheModal className="md">
                                        <button className="the-modal-close" onClick={() => this.setState({ showModalSuccess: false }) }>X</button>

                                        <AlertAssinaturaDone />
                                    </TheModal>
                                </If>

                                <div className="assinatura-l3-text">
                                    <h2 className="f-montserrat f-w-600 text-zed">
                                        <span className="d-block"><span className="f-w-700">+120 revistas por R$ 12,99/mês!</span></span>
                                    </h2>
                                    <h3 className="f-lato w-520 text-zed">Por 1 ano e com 1º mês grátis. Cancele quando quiser. Cadastre-se e acesse agora!</h3>
                                    <div className="d-none d-lg-block assinatura-l3-magazines"></div>
                                </div>

                                <div className="assinatura-l1-form">
                                    <AssinaturaFormLanding3 onSubmit={this.submitAssinatura} />
                                </div>
                                <div className="d-block d-lg-none assinatura-l3-magazines"></div>
                            </div>                    
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => ({ 
    AssinaturaLanding3reducer: state.AssinaturaLanding3reducer,
    VariablesFromJsonReducer: state.VariablesFromJsonReducer,
    AssinaturaReducer: state.AssinaturaReducer,
    assinaturaFormLanding3Reducer: state.assinaturaFormLanding3Reducer,
});

const mapDispatchToProps = dispatch => bindActionCreators({ 
    setAssinaturaConcluidaModalOpened,
    getVariablesFromJson,
    setFormError,
    setFormErrorMessage,
    setSubmitLoading,
    setButtonCadastrarEnable,
    setButtonConcluirEnable,
    setTermosCondicoesModalOpened,
    setPoliticaPrivacidadeModalOpened
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AssinaturaLanding3);